import styled from 'styled-components';
import { Calendar, PageHeader, Form, Input, Skeleton, Button, Tooltip } from "antd";
import useCustomCalendar from "../../hooks/useCustomCalendar";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ancanaApi } from "../../api/ancanaApi";
import FormDrawer from "../../components/FormDrawer";
import axios from "axios";
import useQuery from "../../hooks/useQuery";
import { UnitModel } from "../../types/models/housing";
import RecordSelect from "../../components/fields/RecordSelect";
import { SyncOutlined, ClockCircleTwoTone } from "@ant-design/icons";

const Container = styled.div`
  height: 100%;
`;

moment.locale('en', {
  week: {
    dow: 0,
  },
});

function Rates() {
  const { query } = useQuery();
  const [currentHousePrices, setCurrentHousePrices] = useState<{ amount:number, date: string, source: string }[]>([]);
  const [lodgifySyncDisabled, setLodgifySyncDisabled] = useState(true);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const {
    selectedDateRange,
    headerRender,
    dateCellFullRender,
    setDateStart,
    setDateEnd,
    onPanelChange,
    currentMonthDate,
  } = useCustomCalendar({
    selectable: true,
    dateCellContentRender: (m) => {
      const price = currentHousePrices.find(hp => hp.date === m.format('YYYY-MM-DD'));
      if (loadingPrices) return <Skeleton active paragraph={false} />;
      return (
        <Tooltip title={price?.source === 'prev_yr' ? "last year price" : ""}>
          <div className="disp-flex h-stack-strict gap-xs fs-3 center-text h-center center">
            ${(price?.amount || '-').toLocaleString()} {price?.source === 'prev_yr' && <ClockCircleTwoTone style={{ fontSize: 10 }} />}
          </div>
        </Tooltip>
      );
    },
    filters: [
      <RecordSelect
        recordModel={UnitModel}
        extraFilterQuery="&published=true&has_no_owner=false"
        autoSelectFirst
        filter
      />
    ]
  });
  const selectedUnit = query.get('unit');
  const containerRef = useRef<any>();
  const drawerRef = useRef<any>();
  const inputRef = useRef<any>();

  const fetchCurrentMonthData = () => {
    const start = moment(currentMonthDate).startOf('month').subtract(6, 'days').format('YYYY-MM-DD');
    const end = moment(currentMonthDate).endOf('month').add(14, 'days').format('YYYY-MM-DD');
    if (selectedUnit) {
      setLoadingPrices(true);
      ancanaApi.get(`unit-date-prices/?date_after=${start}&date_before=${end}&unit=${selectedUnit}&page_size=60`).then(res => {
        setCurrentHousePrices(res.data.results);
        setLoadingPrices(false);
      });
    }
  }

  const handleKeyboardEvents = (event: any) => {
    if (event.key === 'Enter') {
      drawerRef.current.open();
    }
    if (selectedDateRange.length > 0) {
      // containerRef.current.children[1].children[1].children[0].tabIndex = 0;
      // containerRef.current.children[1].children[1].children[0].focus();
      if (event.key === 'ArrowRight') {
        setDateStart(selectedDateRange[0].add(1, 'day'));
        setDateEnd(undefined);
      }
      if (event.key === 'ArrowLeft') {
        setDateStart(selectedDateRange[0].subtract(1, 'day'));
        setDateEnd(undefined);
      }
      if (event.key === 'ArrowUp') {
        setDateStart(selectedDateRange[0].subtract(1, 'week'));
        setDateEnd(undefined);
      }
      if (event.key === 'ArrowDown') {
        setDateStart(selectedDateRange[0].add(1, 'week'));
        setDateEnd(undefined);
      }
    }
  }

  useEffect(() => {
    fetchCurrentMonthData();
  }, [currentMonthDate, selectedUnit]);

  useEffect(() => {
    ancanaApi.get(`housing-units/${selectedUnit}/?fields=lodgify_property`).then(res => {
      setLodgifySyncDisabled(!res.data.lodgify_property);
    })
  }, [selectedUnit]);

  return (
    <Container ref={containerRef} tabIndex={0} onKeyDown={handleKeyboardEvents}>
      <PageHeader
        title={`Rental Prices`}
        subTitle={`${currentHousePrices.length.toLocaleString('en-US')} records`}
        extra={[
          <Tooltip title={lodgifySyncDisabled ? 'This unit needs to be linked to a Lodgify property before syncing' : 'Pulls the daily rates from Lodgify'}>
          <Button
            disabled={lodgifySyncDisabled}
            icon={<SyncOutlined />}
            onClick={async () => {
              setLoadingPrices(true);
              await ancanaApi.get(`unit-date-prices/sync/?unit=${selectedUnit}&year=${currentMonthDate.year().toString()}`);
              fetchCurrentMonthData();
            }}
          >
            Sync {currentMonthDate.year()} prices
          </Button>
          </Tooltip>,
          <FormDrawer
            ref={drawerRef}
            actionName="Change"
            recordName="Price"
            handleRecordAction={(data: any) => {
              let dateRequests: any = [];
              if (selectedDateRange.length > 0) {
                let currDate = moment(selectedDateRange[0]).startOf('day').subtract(1, 'days');
                let lastDate = moment(selectedDateRange.length > 1 ? selectedDateRange[1] : selectedDateRange[0]).endOf('day');
                while(currDate.add(1, 'days').diff(lastDate) < 0) {
                  dateRequests.push(ancanaApi.post('unit-date-prices/', {
                    date: currDate.clone().format('YYYY-MM-DD'),
                    unit: selectedUnit,
                    amount: data.amount
                  }));
                }
                axios.all(dateRequests).then(() => fetchCurrentMonthData());
              }
            }}
            onClose={() => {
              containerRef.current.children[1].children[1].children[0].tabIndex = 0;
              containerRef.current.children[1].children[1].children[0].focus();
            }}
            tooltip={selectedDateRange.length === 0 ? "Select a date in the calendar" : ''}
            disabled={selectedDateRange.length === 0}
            refFocus={inputRef}
          >
            <Form.Item
              name="amount"
              rules={[{ required: true }]}
              label={`Price for: ${selectedDateRange[0]?.format('LL')} ${selectedDateRange.length > 1 ? ` to ${selectedDateRange[1].format('LL')}`: ''}`}
            >
              <Input type="number" ref={inputRef} />
            </Form.Item>
          </FormDrawer>
        ]}
        className="mb-md"
      />

      <Calendar
        mode="month"
        headerRender={headerRender}
        dateFullCellRender={dateCellFullRender}
        onPanelChange={onPanelChange}
        onChange={(date: any) => console.log('>>> date', date)}
      />
    </Container>
  )
}

export default Rates;
