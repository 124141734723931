import { Select, Table } from 'antd';
import { useEffect, useRef } from "react";
import dayjs from "dayjs";

import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";

const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const columns = [
  { title: 'Type', dataIndex: ['data', 'object_type'], key: 'type', width: 75 },
  { title: 'Metric', dataIndex: ['data', 'metric'], key: 'metric', width: 100 },
  { title: 'Contact', dataIndex: 'contact_name', key: 'contact_name', width: 250 },
  { title: 'Content', dataIndex: ['data', 'data', 'subject'], key: 'subject'},
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    width: 200,
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
  },
];

function ContactEvents() {
  const elementRef: any = useRef(null);
  const { query, handleFilter } = useQuery();
  const { fetch, pagination, handleChange, isLoading, recordList } = usePaginatedRecordList<any>({ query, recordPath: 'contact-events'});

  useEffect(() => {
    fetch({ pagination });
  }, []);

  return (
    <div ref={elementRef} style={{ height: '100%' }}>
      <h2 className="entity-header">Contact Events</h2>
      <p>{pagination.total?.toLocaleString('en-US')} records</p>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
        <Select
          allowClear
          showSearch
          placeholder="all metrics"
          optionFilterProp="children"
          onChange={(value) => handleFilter('cio_metric', value)}
          defaultValue={query.get('cio_metric')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="opened">opened</Select.Option>
          <Select.Option value="clicked">clicked</Select.Option>
          <Select.Option value="converted">converted</Select.Option>
          <Select.Option value="unsubscribed">unsubscribed</Select.Option>
          <Select.Option value="submitted">submitted</Select.Option>
        </Select>
      </div>

      <Table
        columns={columns}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 160 }}
        size="small"
      />
    </div>
  )
}

export default ContactEvents;
