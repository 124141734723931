import styled from 'styled-components';
import { Modal, Transfer } from 'antd';
import { useEffect, useState } from "react";
import { ancanaApi } from "../api/ancanaApi";
import { ChannelModel, IChannel } from "../types/models/channels";
import RecordTable from "./RecordTable";
import { ShareAltOutlined } from "@ant-design/icons";

const Container = styled.div`
  justify-content: center;
`;

interface RecordChannelsProps {
  recordPath: string;
  recordId: string | number;
  recordName?: string;
  modalView?: boolean;
}

function RecordChannels(props: RecordChannelsProps) {
  const {
    recordPath,
    recordId,
    modalView = true,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  // const [loadingChannels, setLoadingChannels] = useState(false);
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [channelSubs, setChannelSubs] = useState<{id: number, channel: IChannel}[]>([]);
  const baseRecordChannelsUrl = `${recordPath}/${recordId}/channels/`;

  const addChannel = (chanKey: string) => {
    ancanaApi.post(baseRecordChannelsUrl, { channel: chanKey }).then(res => {
      setChannelSubs([...channelSubs, res.data]);
    });
  }

  const removeChannel = (chanKey: string) => {
    const channSub = channelSubs.find(cs => cs.channel.id.toString() === chanKey);
    if (channSub) {
      ancanaApi.delete(`${baseRecordChannelsUrl}${channSub.id}/`).then(res => {
        setChannelSubs(channelSubs.filter(cs => cs.id !== channSub.id));
      });
    }
  }

  useEffect(() => {
    ancanaApi.get('channels?page_size=50').then(res => setChannels(res.data.results));
  }, []);

  useEffect(() => {
    ancanaApi.get(baseRecordChannelsUrl).then(res => {
      setChannelSubs(res.data);
    });
  }, [recordId, recordPath]);

  const Contento = (
    <Container>
      <Transfer<any>
        titles={['Available', 'Subscribed']}
        dataSource={channels.map(channel => ({ title: channel.name, key: channel.id.toString() }))}
        render={item => `#${item.title}`}
        onChange={(targetKeys, direction, moveKeys) => {
          if (direction === 'right') {
            moveKeys.forEach(key => addChannel(key));
          }
          if (direction === 'left') {
            moveKeys.forEach(key => removeChannel(key));
          }
        }}
        targetKeys={channelSubs.map(chanSub => chanSub.channel.id.toString())}
        oneWay
        listStyle={{ width: 250, height: 350 }}
        footer={(((_: any, a: any) => a?.direction === 'left' ? <div className="pd-xs"><RecordTable btnBlock btnSize="small" recordModel={ChannelModel} modalView /></div> : undefined) as any)}
      />
    </Container>
  )

  return modalView ? (
    <>
      <a onClick={() => setModalVisible(true)}><ShareAltOutlined /> Channels</a>
      <Modal
        title={<><ShareAltOutlined /> {props.recordName ? props.recordName : 'Record'} Channels</>}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={580}
      >
        {Contento}
      </Modal>
    </>
  ) : Contento;
}

export default RecordChannels;
