import React, { ReactNode, useEffect, useState } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Tabs, Form, Input, Spin } from 'antd';
import styled from "styled-components";

import { ancanaApi, ancanaApitoken } from "../api/ancanaApi";
import DraggableTabs from "./DraggableTabs";
import DraggableUpload from "./DraggableUpload";
import axios from "axios";


interface RecordGalleriesModalProps {
  recordPath: string;
  recordId: string | number;
  recordName: string;
  children?: ReactNode;
}

function compareNumbers(a: any, b: any) {
  return a.sort_index - b.sort_index;
}

const Container = styled.div`
  .ant-tabs {
    overflow: scroll !important;
  }
`;

function RecordGalleries({ recordPath, recordId, recordName, children }: RecordGalleriesModalProps) {
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [loadingGalleries, setLoadingGalleries] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [galleries, setGalleries] = useState<any>([]);
  const [allImages, setAllImages] = useState<any[]>([]);
  const [currentFiles, setCurrentFiles] = useState<any>([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [form] = Form.useForm();
  const basePath = `${recordPath}/${recordId}/galleries`;
  const baseImgPath = `${recordPath}/${recordId}/gallery-images`;

  const fetchGalleries = () => {
    setLoadingGalleries(true);
    ancanaApi.get(basePath).then(res => {
      setGalleries(res.data);
      if (res.data.length > 0) setActiveKey(res.data[0].id);
      setLoadingGalleries(false);
    })
    setLoadingImages(true);
    ancanaApi.get(baseImgPath).then(res => {
      setAllImages(res.data);
      setLoadingImages(false);
    });
  }

  const handleTabEdit = (key: any, action: string) => {
    switch (action) {
      case "remove":
        const galToDelete = galleries.find((gal: any) => gal.id == key);
        Modal.confirm({
          title: `Confirm delete of "${galToDelete?.name}" gallery`,
          content: 'This will delete all the pictures inside',
          okText: 'Delete',
          onOk: () => {
            if (galToDelete) {
              ancanaApi.delete(`${basePath}/${galToDelete?.id}`).then(res => {
                setGalleries(galleries.filter((gal: any) => gal.id != key));
                if (galleries.length > 0) {
                  setActiveKey(galleries[0].id);
                }
              });
            }
          }
        });
        break;
      case "add":
        const addModal = Modal.confirm({
          title: `Create a new gallery`,
          content: (
            <Form
              form={form}
              onFinish={(data: any) => {
                ancanaApi.post(`${basePath}/`, data).then(res => {
                  setGalleries([...galleries, res.data]);
                  addModal.destroy();
                });
              }}
            >
              <Form.Item label="name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Form>
          ),
          okText: "Create",
          icon: null,
          onOk: (close: any) => form.submit()
        });
        break;
    }
  }

  const handleTabClick = (key: string) => {
    const selectedGal = galleries.find((gal: any) => gal.id == key);
    const editModal = Modal.confirm({
      title: `Edit gallery`,
      content: (
        <Form
          layout="vertical"
          initialValues={{ ...selectedGal }}
          form={form}
          onFinish={(data: any) => {
            ancanaApi.patch(`${basePath}/${selectedGal.id}/`, data).then(res => {
              // setGalleries([...galleries.filter((gal: any) => gal.id == key), res.data]);
              fetchGalleries();
              editModal.destroy();
            });
          }}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      ),
      okText: "Save",
      icon: null,
      onOk: (close: any) => form.submit()
    });
  }

  useEffect(() => {
    fetchGalleries();
  }, [basePath, baseImgPath]);

  useEffect(() => {
    if (activeKey) {
      const galImages = allImages.filter(img => img.gallery == activeKey);
      setCurrentFiles(galImages);
    }
  }, [activeKey, allImages]);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.hd_url);
    setPreviewOpen(true);
  };

  return (
    <Container>
      <DraggableTabs
        onEdit={handleTabEdit}
        // onTabClick={handleTabClick}
        onChange={(key: string) => setActiveKey(key)}
      >
        {galleries.map((gal: any) => (
          <Tabs.TabPane
            tab={gal.name}
            style={{ height: 420 }}
            key={gal.id}
          >
            {/*
            <Upload
              multiple
              action={`${process.env.REACT_APP_API_URL}/v1/${baseImgPath}/`}
              headers={{ 'Authorization': `Token ${ancanaApitoken}` }}
              onPreview={handlePreview}
              data={(file) => {
                return {
                  image: file,
                  gallery: gal.id
                }
              }}
              listType="picture-card"
              fileList={currentFiles.map((galImg: any) => ({
                uid: galImg.id,
                name: galImg.name,
                status: !!galImg.status ? galImg.status : 'done',
                percent: 99.9,
                url: galImg.thumbnail,
                hd_url: galImg.hd,
              }))}
              onChange={({ file }) => {
                if (file.status !== 'removed') {
                  setAllImages(allImages => ([
                    ...allImages.filter((img: any) => img.id !== file.uid),
                    {
                      id: file.response ? (file.response as any).id : file.uid,
                      name: file.name,
                      status: file.response ? 'done' : 'uploading',
                      file: file.response ? (file.response as any).file : `none/${file.name}?`,
                      thumbnail: file.response ? (file.response as any).thumbnail : null,
                      hd: file.response ? (file.response as any).hd : null,
                      gallery: gal.id
                    },
                  ]));
                }
              }}
              progress={{ status: 'active', showInfo: false, strokeWidth: 5 }}
              onRemove={file => {
                ancanaApi.delete(`${baseImgPath}/${file.uid}/`).then(res => {
                  if (res.status === 200) {
                    // setCurrentFiles(currentFiles.filter((cf: any) => cf.id !== file.uid));
                    setAllImages(allImages.filter(img => img.id !== file.uid));
                  }
                });
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
            */}

            {(loadingGalleries || loadingImages) ? (
              <div className="disp-flex full-width pd-lg center">
                <Spin /> Loading images...
              </div>
            ) : (
              <DraggableUpload
                multiple
                action={`${process.env.REACT_APP_API_URL}/v1/${baseImgPath}/`}
                headers={{ 'Authorization': `Token ${ancanaApitoken}` }}
                data={(file) => ({ image: file, gallery: gal.id })}
                listType="picture-card"
                fileList={currentFiles.sort(compareNumbers).map((galImg: any) => ({
                  galImg,
                  uid: galImg.id,
                  name: galImg.name,
                  status: !!galImg.status ? galImg.status : 'done',
                  percent: 99.9,
                  url: galImg.thumbnail,
                  hd_url: galImg.hd,
                }))}
                progress={{ status: 'active', showInfo: false, strokeWidth: 5 }}
                onSort={({ fileList }: any) => {
                  setCurrentFiles(fileList.map((f: any, idx: number) => {
                    return { ...f.galImg, sort_index: idx };
                  }));
                  let patchReqs: any = [];
                  fileList.forEach((f: any, idx: number) => {
                    patchReqs.push(ancanaApi.patch(`${baseImgPath}/${f.uid}/`, {sort_index: idx}));
                  });
                  axios.all(patchReqs).then(() => {
                    ancanaApi.get(baseImgPath).then(res => {
                      setAllImages(res.data);
                    });
                  });
                }}
                onChange={({ file }) => {
                  if (file.status !== 'removed') {
                    setAllImages(allImages => ([
                      ...allImages.filter((img: any) => img.id !== file.uid),
                      {
                        id: file.response ? (file.response as any).id : file.uid,
                        name: file.name,
                        status: file.response ? 'done' : 'uploading',
                        file: file.response ? (file.response as any).file : `none/${file.name}?`,
                        thumbnail: file.response ? (file.response as any).thumbnail : null,
                        hd: file.response ? (file.response as any).hd : null,
                        gallery: gal.id
                      },
                    ]));
                  }
                }}
                onPreview={handlePreview}
                onRemove={file => {
                  ancanaApi.delete(`${baseImgPath}/${file.uid}/`).then(res => {
                    if (res.status === 200) {
                      // setCurrentFiles(currentFiles.filter((cf: any) => cf.id !== file.uid));
                      setAllImages(allImages.filter(img => img.id !== file.uid));
                    }
                  });
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </DraggableUpload>
            )}
          </Tabs.TabPane>
        ))}
      </DraggableTabs>

      {/*
      <Tabs
        onChange={(key: string) => setActiveKey(key)}
        type="editable-card"
        onEdit={handleTabEdit}
      >
        {galleries.map((gal: any) => (
          <Tabs.TabPane
            tab={gal.name}
            style={{ height: 420 }}
            key={gal.id}
          >
            <Upload
              multiple
              action={`${process.env.REACT_APP_API_URL}/v1/${baseImgPath}/`}
              headers={{ 'Authorization': `Token ${ancanaApitoken}` }}
              onPreview={handlePreview}
              data={(file) => {
                return {
                  image: file,
                  gallery: gal.id
                }
              }}
              listType="picture-card"
              fileList={currentFiles.map((galImg: any) => ({
                uid: galImg.id,
                name: galImg.name,
                status: !!galImg.status ? galImg.status : 'done',
                percent: 99.9,
                url: galImg.thumbnail,
                hd_url: galImg.hd,
              }))}
              onChange={({ file }) => {
                if (file.status !== 'removed') {
                  setAllImages(allImages => ([
                    ...allImages.filter((img: any) => img.id !== file.uid),
                    {
                      id: file.response ? (file.response as any).id : file.uid,
                      name: file.name,
                      status: file.response ? 'done' : 'uploading',
                      file: file.response ? (file.response as any).file : `none/${file.name}?`,
                      thumbnail: file.response ? (file.response as any).thumbnail : null,
                      hd: file.response ? (file.response as any).hd : null,
                      gallery: gal.id
                    },
                  ]));
                }
              }}
              progress={{ status: 'active', showInfo: false, strokeWidth: 5 }}
              onRemove={file => {
                ancanaApi.delete(`${baseImgPath}/${file.uid}/`).then(res => {
                  if (res.status === 200) {
                    // setCurrentFiles(currentFiles.filter((cf: any) => cf.id !== file.uid));
                    setAllImages(allImages.filter(img => img.id !== file.uid));
                  }
                });
              }}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </Tabs.TabPane>
        ))}
        {!loadingGalleries && galleries.length === 0 && (
          <div>
            <Empty
              imageStyle={{
                height: 60,
              }}
            >
              <Button type="primary">Create Now</Button>
            </Empty>
          </div>
        )}
      </Tabs>
      */}

      {!!previewImage &&
        <Modal
          width={900}
          visible={previewOpen}
          title="Image"
          footer={null}
          onCancel={() => {
            setPreviewOpen(false);
            setTimeout(() => setPreviewImage(undefined), 250);
          }}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      }
    </Container>
  )
}

export default RecordGalleries;
