import React, { useEffect, useRef, useState } from 'react';
import { Input, Select, Table, message, Popconfirm, Button, Drawer, Form } from 'antd';
import type { ColumnGroupType } from "antd/es/table";
import styled from "styled-components";

import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";
import { dataApi } from "../../api/dataApi";

import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const { Search } = Input;
const { Option } = Select;

const Container = styled.div`
`;

interface AdRecordType {
  id: number;
  name: string;
  target_label: string;
}

const allColsSettings = { ellipsis: true }
const getColumns = (deleteRecord: any) => [
  { title: 'Name', dataIndex: 'name', sorter: true, width: 250, fixed: 'left' },
  { title: 'Target', dataIndex: 'target_label', sorter: true, key: 'target', width: 200 },
  { title: 'Facebook ID', dataIndex: 'external_id', sorter: true, key: 'fb_id', width: 175 },
  {
    title: 'Modified At',
    dataIndex: ['data', 'updated_time'],
    key: 'updated_time',
    sorter: true,
    width: 155,
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
  },
  {
    title: 'Created At',
    dataIndex: ['data', 'created_time'],
    key: 'created_at',
    sorter: true,
    width: 155,
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
  },
  {
    title: 'Actions',
    width: 135,
    render: (txt: any, obj: any) => {
      return  (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Popconfirm
            title={`Delete "${obj.name}" ad?`}
            onConfirm={() => deleteRecord(obj.id)}
            // onCancel={() => {}}
            okText="Yes"
            //cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        </div>
      )
    }
  }
].map((e: any) => ({ ...e, ...allColsSettings }));


function Index() {
  const { query, handleSearch, handleFilter } = useQuery();
  const {
    fetch,
    pagination,
    handleChange,
    isLoading,
    recordList,
    createRecord,
    deleteRecord
  } = usePaginatedRecordList<AdRecordType>({ query, recordPath: 'ads' });
  const elementRef: any = useRef(null);
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    fetch({ pagination });
  }, []);

  return (
    <Container>
      <div>
        <div style={{ display: 'inline-block' }}>
          <h2 className="entity-header">Ads</h2>
          <p>{pagination.total?.toLocaleString('en-US')} records</p>
        </div>

        <div style={{ float: 'right' }}>
          <Button
            type="primary"
            onClick={() => setCreateOpen(true)}
          >
            Create Ad
          </Button>
        </div>

      </div>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
        <Search
          placeholder="Search name, target, id..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
      </div>

      <div style={{ width: '100%', margin: 16 }} />

      <Table
        columns={getColumns(deleteRecord)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="small"
      />

      <Drawer
        title="Create Ad"
        placement="right"
        width={500}
        onClose={() => setCreateOpen(false)}
        visible={createOpen}
      >
        <Form
          // ref={formRef}
          style={{ width: '100%', flex: 1, height: '100%' }}
          name="basic"
          // initialValues={{ name: selectedResource?.name, url: selectedResource?.url }}
          onFinish={(data) => { createRecord(data); setCreateOpen(false) }}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Target"
            name="target_label"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Fb Id"
            name="external_id"
            rules={[{ required: true, message: "'Fb Id' is required" }]}
          >
            <Input />
          </Form.Item>

          <br/><br/><br/><br/><br/>

          <div style={{ padding: '24px', backgroundColor: '#f3f3f3', margin: '0 -24px', position: 'fixed', bottom: 0, width: '100%' }}>
            <Form.Item style={{ display: 'inline-block', margin: '0 12px 0 0' }}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
            <Button onClick={() => setCreateOpen(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </Drawer>
    </Container>
  )
}

export default Index;
