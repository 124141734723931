import styled from 'styled-components';
import { useEffect, useState } from "react";
import { ancanaApi } from "../../api/ancanaApi";

import { Tag, Descriptions } from 'antd';


const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

function Permissions() {
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    ancanaApi.get(`groups?page_size=25`).then(res => {
      setGroups(res.data.results);
    });
  }, []);

  return (
    <Container>
      <Descriptions
        title="Permission Groups"
        layout="horizontal"
        column={1}
        bordered
      >
        <Descriptions.Item label="Super User" labelStyle={{ fontWeight: 500 }}>
          <Tag color="blue">Can view, add, change, and delete everything</Tag>
        </Descriptions.Item>

          {groups.map((group: any) => (
          <Descriptions.Item key={group.id} label={group.name} contentStyle={{ width: '85%' }}>
            {group.permissions.map((permission: any) => (<Tag key={permission.id}>{permission.name}</Tag>))}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Container>
  )
}

export default Permissions;
