import { Button, Input, Select, Table } from 'antd';
import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { CloseOutlined } from '@ant-design/icons';

import usePaginatedRecordList, { QuerySetterParams } from "../../hooks/usePaginatedRecordList";
import { formatServerText } from "../../utils";
import useQuery from "../../hooks/useQuery";
import { Link } from "react-router-dom";

const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const { Search } = Input;
const { Option } = Select;


const allColsSettings = { ellipsis: true }
const columns = [
  {
    title: 'Name',
    dataIndex: 'first_name',
    sorter: true,
    width: 225,
    fixed: 'left',
    render: (text: string, c: any) => <Link to={`/crm/contacts/${c.id}`}>{c.first_name} {c.last_name}</Link>
  },
  { title: 'Email', dataIndex: 'email', sorter: true, key: 'email', width: 250 },
  { title: 'Phone', dataIndex: 'phone', sorter: true, key: 'phone', width: 175 },
  { title: 'Interested in', dataIndex: ['property_data', 'interested_in'], key: 'interested_in', width: 125 },
  {
    title: 'Analytics source',
    dataIndex: ['property_data', 'hs_analytics_source'],
    key: 'hs_analytics_source',
    width: 110,
    render: (text: string) => <>{formatServerText(text)}</>
  },
  {
    title: 'Lead Status',
    dataIndex: ['property_data', 'hs_lead_status'],
    key: 'hs_lead_status',
    width: 120,
    render: (text: string) => <>{formatServerText(text)}</>
  },
  {
    title: 'Broker',
    dataIndex: 'agent',
    key: 'agent',
    width: 110,
    render: (t: string, c: any) => <a href={`/crm/contacts?agent_id=${c.agent?.id}`}>{c.agent?.name}</a>
  },
  {
    title: 'Modified At',
    dataIndex: 'modified_at',
    key: 'modified_at',
    sorter: true,
    width: 155,
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    width: 155,
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
  },
  { title: 'Events', dataIndex: 'event_count', sorter: true, key: 'contactevent', width: 80 },
  { title: 'Fb Lead ID', dataIndex: ['property_data', 'facebook', 'lead', 'id'], sorter: true, key: 'fb_id', width: 175 },
].map((e: any) => ({ ...e, ...allColsSettings }));

function customQuerySorter({ query, sorter }: QuerySetterParams) {
  if (!!sorter.order) {
    query.set('ordering', `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`);
  } else {
    query.delete('ordering');
  }
}

function Contacts() {
  const { query, handleSearch, handleFilter } = useQuery();
  const {
    fetch,
    pagination,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({ query, recordPath: 'contacts', customQuerySetter: customQuerySorter });
  const elementRef: any = useRef(null);

  useEffect(() => {
    fetch({ pagination });
  }, []);

  return (
    <div ref={elementRef} style={{ height: '100%' }}>
      <h2 className="entity-header">Contacts</h2>
      <p>{pagination.total?.toLocaleString('en-US')} records</p>

      <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
        <Search
          placeholder="Search name, email, phone..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <Select
          allowClear
          showSearch
          placeholder="facebook lead"
          optionFilterProp="children"
          onChange={(value) => handleFilter('is_facebook_lead', value)}
          defaultValue={query.get('is_facebook_lead')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="true">is a facebook lead</Option>
          <Option value="false">not a facebook lead</Option>
        </Select>

        {!!query.get('agent_id') && (
          <Button onClick={() => handleFilter('agent_id', null)}>
            agent_id={query.get('agent_id')}
            <CloseOutlined />
          </Button>
        )}
        {!!query.get('agent_null') && (
          <Button onClick={() => handleFilter('agent_null', null)}>
            {!query.get('agent_null') ? 'Contactos sin broker' : 'Contactos con broker'}
            <CloseOutlined />
          </Button>
        )}
      </div>

      <div style={{ width: '100%', margin: 16 }} />

      <Table
        columns={columns}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="small"
      />
    </div>
  );
}

export default Contacts;
