import styled from 'styled-components';
import { Avatar, Button, Comment, Drawer, Descriptions } from "antd";
import { CommentOutlined, MessageOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { ancanaApi } from "../api/ancanaApi";
import { IComment } from "../types/models/generics";
import moment from "moment";
import { ButtonProps } from "antd/lib/button/button";

const Container = styled.div`
`;

const Content = styled.div`
  .comment {
    border: 1px #e8e8e8 solid;
    padding: 0 16px;
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: white;
  }
`;


interface RecordCommentsProps {
  recordPath: string;
  recordId: string | number;
  recordName: string;
  showEmpty?: boolean;
  buttonProps?: ButtonProps;
  buttonCta?: string;
  className?: string;
}

function RecordComments(props: RecordCommentsProps) {
  const { recordPath, recordId, recordName, showEmpty = false, buttonCta = 'Comments' } = props;
  const [visible, setVisible] = useState(false);
  const [comments, setComments] = useState<IComment[]>([]);

  useEffect(() => {
    ancanaApi.get(`${recordPath}/${recordId}/comments/`).then(res => {
      setComments(res.data)
    });
  }, [recordId, recordPath]);

  return (
    <Container className={props.className}>
      {(showEmpty || comments.length !== 0) && (
      <Button
        disabled={comments.length === 0}
        onClick={() => setVisible(true)}
        icon={<CommentOutlined />}
        {...props.buttonProps}
      >
        {buttonCta}
      </Button>
      )}
      <Drawer
        // width={400}
        title={`Comments: ${recordName}`}
        visible={visible}
        onClose={() => setVisible(false)}
        bodyStyle={{ padding: 12, backgroundColor: '#f5f5f5' }}
      >
        <Content>
          {comments.map(comment => {
            const dataEntries = !!comment.data && Object.entries(comment.data).filter(([k, v]) => !!v);
            return (
            <Comment
              className="comment"
              key={comment.id}
              content={(
                <div>
                  <p
                    className="mt-sm"
                    style={{ fontWeight: 500, fontSize: 15 }}
                  >
                    {comment.content}
                  </p>
                  {!!dataEntries && dataEntries.length > 0 && (
                    <>
                      <small className="mt-sm">Extra information:</small>
                      <Descriptions
                        bordered
                        column={1}
                        size="small"
                        labelStyle={{ fontWeight: 300, fontSize: 13, padding: '2px 6px', letterSpacing: '-0.5px', lineHeight: 1.25 }}
                        contentStyle={{ fontWeight: 400, fontSize: 13, padding: '2px 6px', letterSpacing: '-0.5px', lineHeight: 1.25 }}
                      >
                        {dataEntries.map(([k, v]) => (
                          <Descriptions.Item label={k.replace(/_/g, ' ')}>{!!v ? v.toString() : ' - '}</Descriptions.Item>
                        ))}
                      </Descriptions>
                    </>
                  )}
                </div>
              )}
              author={comment.author_name.slice(0, 30)+'...'}
              datetime={moment(comment.created_at).fromNow()}
              actions={[
                // <span key="reply" className="right-text"><MessageOutlined /> Reply to</span>,
              ]}
            />
          )})}
        </Content>
      </Drawer>
    </Container>
  )
}

export default RecordComments;
