import styled from 'styled-components';
import { Button, Modal, Steps, Upload, Form, Select, Table, DatePicker } from "antd";
import { LeftOutlined, RightOutlined, InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Papa from 'papaparse';
import RecordSelect from "./fields/RecordSelect";
import { UnitModel } from "../types/models/housing";
import { CurrencyModel, CurrencyType } from "../types/coreModels";
import moment from "moment/moment";
import { ancanaApi } from "../api/ancanaApi";
// const fs = require('fs');


const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 200px);

  .steps-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 16px;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    height: calc(100% - 32px);
    align-content: center;
    overflow-y: scroll;
    // justify-content: center;
  }
  label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .uploader {
    width: 100%;
  }

  .ant-upload-list {

  }
`;

const invoiceProperties = ['date', 'description', 'amount'];

function TabularFileImportWizard({ onSuccess }: any) {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [currentReady, setCurrentReady] = useState(-1);
  const [parsedRows, setParsedRows] = useState<string[][]>([]);
  const [rowMappings, setRowMappings] = useState<{ colIndex: number, property: string }[]>([]);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const reset = () => {
    setCurrent(0);
    setCurrentReady(-1);
    setParsedRows([]);
    setRowMappings([]);
    form.resetFields();
  }

  useEffect(() => {
    if (current > 0) {
      if (rowMappings.length >= invoiceProperties.length) {
        setCurrentReady(1);
      } else {
        setCurrentReady(0);
      }
    }
  }, [rowMappings]);

  return (
    <>
      <Button onClick={() => setOpen(true)} icon={<DownloadOutlined />}>
        Import CSV
      </Button>
      <Modal
        title="Import CSV"
        centered
        visible={open}
        width="900px"
        onCancel={() => { setOpen(false); setCurrent(0); }}
        footer={
          <div className="full-width justify-between" style={{ display: 'flex' }}>
            <Button type="primary" ghost disabled={current === 0} onClick={() => setCurrent(current - 1)}>
              <LeftOutlined /> Back
            </Button>
            {current < 2 && (
              <Button
                type="primary"
                onClick={() => setCurrent(current + 1)}
                disabled={currentReady < current}
              >
                Next <RightOutlined />
              </Button>
            )}
            {current === 2 && (
              <Button type="primary" onClick={() => form.submit()} loading={saving}>
                Finish Import
              </Button>
            )}
          </div>
        }
      >
        <Container>
          <Steps current={current}>
            <Steps.Step title="Upload" />
            <Steps.Step title="Map" />
            <Steps.Step title="Details" />
          </Steps>

          {current === 0 && (
            <div className="steps-content">
              <div className="fs-3-5 fw-500 mb-sm mt-lg">
                Select a file
              </div>
              <label>Invoice lines</label>
              <Upload.Dragger
                name="file"
                multiple={false}
                beforeUpload={(file: any) => {
                  Papa.parse(file, {
                    complete: function(results: any) {
                      setParsedRows(results.data);
                    }
                  });
                  setCurrentReady(0);
                }}
                className="uploader"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                </p>
              </Upload.Dragger>
            </div>
          )}

          {current === 1 && (
            <div className="steps-content">
              <div className="mt-lg fw-500 fs-3-5">
                Map each invoice line property to a column of the CSV
              </div>
              <p>
                Select a property on the "Import As" column
              </p>
              <Table
                columns={[
                  { title: 'Column header from file', dataIndex: 'heading' },
                  { title: 'Preview Information', dataIndex: 'values' },
                  // { title: 'Mapped', render: () => <div></div> },
                  { title: 'Import As', render: (a, b, c) => <div>
                    <Select
                      placeholder="Select property"
                      allowClear
                      style={{ width: 175 }}
                      defaultValue={rowMappings.find(mapping => mapping.colIndex === c)?.property}
                      onClear={() => setRowMappings(rowMappings.filter(mapping => mapping.colIndex !== c))}
                      onSelect={(e: any) => setRowMappings([...rowMappings, { colIndex: c, property: e }])}
                      options={invoiceProperties.map(invProp => ({
                        label: invProp,
                        value: invProp,
                        disabled: rowMappings.map(rm => rm.property).includes(invProp)
                      }))}
                    />
                  </div> },
                ]}
                dataSource={
                  parsedRows[0].map((rowHeader: string, index: number) => ({
                    heading: rowHeader,
                    values: parsedRows.slice(0,4).filter((row, i) => i > 0).map(row => <div>{row[index]}</div>)
                  }))
                }
                pagination={false}
                size="middle"
              />
            </div>
          )}

          {current === 2 && (
            <div className="steps-content">
              <Form
                layout="vertical"
                form={form}
                onFinish={(data: any) => {
                  const lineItems = parsedRows
                    .filter((row, i) => i > 0)
                    .map(row => Object.fromEntries(
                      rowMappings.map(rm => ([rm.property, row[rm.colIndex]]))
                    ));
                  const payload = {
                    ...data,
                    'billing_period': data['billing_period'].format('YYYY-MM-DD'),
                    'due_date': data['due_date'].format('YYYY-MM-DD'),
                    'lines': lineItems
                  }
                  setSaving(true);
                  ancanaApi.post('invoices/create-for-each-owner/', payload).then(res => {
                    setSaving(false);
                    setOpen(false);
                    if (onSuccess) onSuccess();
                    reset();
                  })
                }}
              >
                <div className="mt-lg fw-500 fs-3-5">
                  Create Invoices for each of the house owners
                </div>
                <p>
                  You'll still be able to edit each invoice.
                </p>
                <RecordSelect recordModel={UnitModel} extraFilterQuery="&has_no_owner=false" />
                <RecordSelect<CurrencyType> recordModel={CurrencyModel} defaultValue="f36b4827-23a1-4a38-83c0-6640dd0c9f53" />
                <Form.Item
                  name="billing_period"
                  label="Billing period"
                  initialValue={moment().startOf('month')}
                  rules={[{ required: true }]}
                >
                  <DatePicker.MonthPicker />
                </Form.Item>
                <Form.Item
                  name="due_date"
                  label="Due date"
                  initialValue={moment().endOf('month')}
                  rules={[{ required: true }]}
                >
                  <DatePicker />
                </Form.Item>
              </Form>
            </div>
          )}
        </Container>
      </Modal>
    </>
  );
}

export default TabularFileImportWizard;
