import type { RecordModel } from "../core";
import { Input } from "antd";


export type IChannel = {
  id: number;
  name: string;
  description?: string;
}

export const ChannelModel: RecordModel<IChannel> = {
  name: "Channel",
  path: "channels",
  selectQuery: "&omit=description",
  tableQuery: "",
  toString(r: IChannel): string {
    return r.name;
  },
  customCreateFields: [
    // { name: 'name', component: () => <Input on /> }
  ]
}
