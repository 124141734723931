import styled from 'styled-components';
import { IComment } from "../types/models";
import { Popover } from "antd";
import CommentDetail from "./CommentDetail";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;

  .triangle {
    position: absolute;
    right: 0;
    margin-top: 1px;
    margin-right: 2px;
    width: 16px;
    height: 16px;
    clip-path: polygon(0 0,100% 0,100% 100%);
    background: #CCA274;
  }
`;

interface DateCommentProps {
  comment: IComment;
  createComment: any;
  deleteComment: any;
  editComment: any;
  removeEmptyComment: any;
}

function DateComment(props: DateCommentProps) {
  const { comment, removeEmptyComment, deleteComment, editComment, createComment } = props;

  return (
    <Popover
      visible={comment.id === 0 ? true : undefined}
      onVisibleChange={(visible) => visible ? undefined : removeEmptyComment()}
      trigger={comment.id === 0 ? 'click' : 'hover'}
      placement="topRight"
      content={(
        <div style={{ maxWidth: 300 }}>
          <CommentDetail
            creating={comment.id === 0}
            comment={comment}
            createComment={createComment}
            deleteComment={deleteComment}
            editComment={editComment}
            onCancel={removeEmptyComment}
          />
        </div>
      )}
    >
      <Container>
        <div className="triangle" />
      </Container>
    </Popover>
  )
}

export default DateComment;
