import { Form, Input, Button, Checkbox } from 'antd';
import styled from "styled-components";
import useAuth from "../hooks/useAuth";

const Container = styled.div`
  flex-direction: column;
  margin: auto;
  max-height: 100vh;
  width: 500px;
  padding: 32px 48px;
  display: flex;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
  }
`;

function LoginForm() {
  const { login } = useAuth();

  return (
    <Container>
      <div className="fs-4 mb-lg">
        Ancana admin panel
      </div>

      <Form
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={login}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Checkbox>Remember me</Checkbox>

        {/*
        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
        </Form.Item>
        */}

        <Form.Item>
          <Button className="mt-md" style={{ width: '100%' }} type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default LoginForm;
