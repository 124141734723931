import { Card, Select, DatePicker } from 'antd';
import { Line } from '@ant-design/plots';
import styled from 'styled-components';
import useQuery from "../hooks/useQuery";
import { Identifiable, RecordModel, RecordsetFilters } from "../types/core";
import { ReactNode, useEffect, useState } from "react";
import { dataApi } from "../api/dataApi";
import moment from "moment/moment";

const Container = styled.div`
`;

interface RecordTimeLGrpahProps<RecordType> {
  recordModel: RecordModel<RecordType>;
  recordsetFilters?: RecordsetFilters;
  timeField?: string;
  className?: string;
  footer?: (records: RecordType[]) => ReactNode;
}

function RecordTimeLGraph<RecordType extends Identifiable>(props: RecordTimeLGrpahProps<RecordType>) {
  const {
    recordModel,
    timeField = 'created_at'
  } = props;
  const [granularity, setGranularity] = useState<'day' | 'hour'>('hour');
  const [dateRange, setDateRange] = useState<any>([
    moment().subtract(4, "week").startOf('day'),
    moment().startOf('day')
  ])
  const [loading, setLoading] = useState<boolean>(false);
  const [series, setSeries] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    dataApi.get(recordModel.path+`/time_series/?group=${granularity}&${timeField}&date_start=${dateRange[0].toISOString()}&date_end=${dateRange[1].toISOString()}`).then(res => {
      setSeries(res.data);
      setLoading(false);
    });
  }, [granularity, dateRange]);

  return (
    <Container>
      <Card
        className="mb-md"
        size="small"
        title={`${recordModel.name} Received`}
        extra={(
          <div className="row-actions">
            <DatePicker.RangePicker
              defaultValue={dateRange}
              onChange={(dates) => setDateRange(dates)}
            />
            <Select value={granularity} onSelect={(val: any) => setGranularity(val)}>
              <Select.Option value="hour">Per Hour</Select.Option>
              <Select.Option value="day">Per Day</Select.Option>
              <Select.Option value="week">Per Week</Select.Option>
              <Select.Option value="month">Per Month</Select.Option>
            </Select>
          </div>
        )}
      >
        <Line
          loading={loading}
          width={400}
          height={200}
          // padding="auto"
          xField="timestamp"
          yField="value"
          xAxis={{
            label: { formatter: (text: any) => moment(text).format('lll')},
          }}
          data={series}
          slider={{
            start: 0.75,
            end: 1,
            formatter: (text: any) => moment(text).format('lll')
          }}
          /*
          onEvent={(c: any, evt: any) => {
            if (evt.type === 'slider:valuechanged'){
              console.log('evt', evt.type, evt.gEvent.originalEvent.value);
            }
          }}
          */
          lineStyle={{ stroke: '#a26a2f', strokeOpacity: 0.75, lineWidth: 1.5 }}
        />
      </Card>
    </Container>
  )
}

export default RecordTimeLGraph;
