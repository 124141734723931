import { Card, Tag, Tooltip } from 'antd';
import { EditOutlined, EllipsisOutlined, EyeOutlined, EyeInvisibleOutlined, ReadOutlined, CodepenOutlined } from "@ant-design/icons";
import { localizePrice } from "../utils";

const { Meta } = Card;


const MediaDisplay: any = {
  'brochure': {
    icon: <ReadOutlined />,
    color: 'green',
  },
  'blueprint': {
    icon: <CodepenOutlined />,
    color: 'blue'
  },
}


function ListingPreview({ listing, currencies }: { listing: any, currencies: any[] }) {
  return (
    <Card
      cover={<div style={{ background: `url(${listing.cover_url}) no-repeat center /cover`, height: 200 }} />}
      actions={[
        <Tooltip title={listing.published ? "view listing" : "listing not published"}>
          {listing.published ? (
            <a href={`https://ancana.co/listing/${listing.slug}`} target="_blank" rel="noreferrer"><EyeOutlined key="link" /></a>
          ) : (
            <EyeInvisibleOutlined key="link" />
          )}
        </Tooltip>,
        <Tooltip title="edit listing">
          <a href={`https://prod.api.ancana.co/admin/houses/houseprototypeproxy/${listing.id}/`}  target="_blank" rel="noreferrer"><EditOutlined key="edit" /></a>
        </Tooltip>,
        <Tooltip title="more actions"><EllipsisOutlined key="ellipsis" /></Tooltip>,
      ]}
    >
      <Meta
        title={`${listing.condo_name}, ${listing.name}`}
        description={localizePrice(
          listing.price / 100,
          currencies.find((c: any) => c.id === listing.currency_id)?.iso,
          'es'
        )}
      />
      <div style={{ marginTop: 12 }}>
        {/*listing.media.map((m: any) => (
          <Tag
            icon={MediaDisplay[m.group_name]?.icon}
            color={MediaDisplay[m.group_name]?.color || 'default'}>
            {m.group_name} ({m.language})
          </Tag>
        ))*/}
      </div>
    </Card>
  )
}

export default ListingPreview;
