import React, { ReactNode, useState } from "react";
import { Modal } from 'antd';

import RecordGalleries from "./RecordGalleries";


interface RecordGalleriesModalProps {
  recordPath: string;
  recordId: string | number;
  recordName: string;
  children?: ReactNode;
}

function RecordGalleriesModal({ recordPath, recordId, recordName, children }: RecordGalleriesModalProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div onClick={() => setVisible(true)}>
        {children ? children : <a>Galleries</a>}
      </div>
      <Modal
        title={`${recordName}'s Image Galleries`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="832px"
      >
        <RecordGalleries recordPath={recordPath} recordId={recordId} recordName={recordName} />
      </Modal>
    </>
  )
}

export default RecordGalleriesModal;
