import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { dataApi } from "../../api/dataApi";
import { Button, Modal, Form, Input, Card, Breadcrumb, List, Tooltip, Dropdown, Menu, message, Upload } from 'antd';
import {
  FolderOpenOutlined,
  EditOutlined,
  PlusOutlined,
  FolderOutlined,
  FileTextOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  EyeOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { useSearchParams,  useNavigate, useLocation } from "react-router-dom";
import Evaporate from 'evaporate';
import { sha256 as SHA256 } from "js-sha256";
const MD5 = require('js-md5');

const evaConfig = {
  signerUrl: 'https://propfinder.ancana.mx/api/resources/aws_sign/',
  aws_key: 'AKIA5AQNKNYJ4YFEC5PG',
  bucket: 'ancana-drive',
  awsRegion: 'us-west-2',
  computeContentMd5: true,
  // cryptoMd5Method: (data: any) => CryptoJs.MD5(data),
  // cryptoMd5Method: (d: any) => Crypto.createHash('md5').update(d).digest('base64'),
  // cryptoMd5Method: (d: any) => crypto.algo.MD5.create().update(String.fromCharCode.apply(null, d)).finalize().toString(crypto.enc.Base64),
  cryptoMd5Method: (data: any) => MD5.create().update(data).base64(),
  cryptoHexEncodedHash256: (data: any) => SHA256.create().update(data).hex()
};

const Container = styled.div`

  .ant-modal-title {
    text-transform: capitalize;
  }
`;

interface ModalState {
  opened: boolean;
  type: 'folder' | 'file',
  action: 'create' | 'edit';
}

function Resources() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const formRef = useRef<any>();
  const [evaInstance, setEvaInstance] = useState<any>(null);

  const [resource, setResource] = useState<any>(null);
  const [loadingResources, setLoadingResources] = useState<boolean>(false);
  const [resources, setResources] = useState<any[]>([]);

  const [modalState, setModalState] = useState<ModalState>({ opened: false, type: 'folder', action: 'create' });
  const [selectedResource, setSelectedResource] = useState<any>(null);

  const resourcedId = searchParams.get('selected');

  const handleModalSave = (values: any) => {
    if (modalState.action === 'create') {
      dataApi.post('resources/', {
        ...values,
        type: modalState.type,
        is_folder: modalState.type === 'folder',
        parent: resourcedId
      }).then(res => {
        res.status === 201 ? window.location.reload() : message.error(res.statusText)
      });
    } else {
      dataApi.patch(`resources/${selectedResource.id}/`, values).then(res => {
        res.status === 200 ? window.location.reload() : message.error(res.statusText);
      });
    }
  }

  const handleDelete = (resource: any) => {
    Modal.confirm({
      title: `Delete "${resource.name}"`,
      content: 'It will also delete all of its contents. This cannot be undone.',
      okText: 'Delete',
      onOk() {
        dataApi.delete(`resources/${resource.id}/`).then(res => {
          window.location.reload();
        });
      }
    });
  }

  const uploadFile = (evaInstance: any) => {
    const file = new File(["awdadw"], "file_object_to_upload");

    const addConfig = {
      name: file.name,
      file: file,
      progress: (progressValue: any) => console.log('Progress', progressValue),
      complete: (_xhr: any, awsKey: any) => console.log('Complete!', _xhr, awsKey),
    }

    evaInstance.add(addConfig).then(
      (awsObjectKey: any) =>
        console.log('File successfully uploaded to:', awsObjectKey),
      (reason: any) =>
        console.log('File did not upload sucessfully:', reason)
    )
  }


  useEffect(() => {
    setLoadingResources(true);
    if (resourcedId) {
      dataApi.get(`resources/${resourcedId}/`).then(res => {
        console.log('res', res.data);
        setResources(res.data.children);
        setResource(res.data);
        setLoadingResources(false);
      });
    } else {
      dataApi.get('resources/?is_root=true').then(res => {
        console.log('resources', res.data.results);
        setResources(res.data.results);
        setResource(null);
        setLoadingResources(false);
      });
    }
  }, [location]);

  useEffect(() => {
    formRef.current?.resetFields();
  }, [selectedResource]);

  useEffect(() => {
    Evaporate.create(evaConfig).then(eva => {
      setEvaInstance(eva);
      uploadFile(eva);
    }).catch(err => console.log('Evaporate create Error:', err));
  }, []);

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'space-between' }}>
        <div>
          <h2 className="entity-header">Resources</h2>
          <p>{resources.length.toLocaleString('en-US')} records</p>
        </div>

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <div
                  onClick={() => {
                    setModalState({ opened: true, type: 'folder', action: 'create' });
                    setSelectedResource(null);
                  }}
                  style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }}
                >
                  <FolderOutlined /> New Folder
                </div>
              </Menu.Item>
              <Menu.Item key="1">
                <div
                  onClick={() => {
                    setModalState({ opened: true, type: 'file', action: 'create' });
                    setSelectedResource(null);
                  }}
                  style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }}
                >
                  <FileTextOutlined /> New File
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            // shape="round"
            type="primary"
            // icon={<PlusOutlined />}
          >
            Create Resource
          </Button>
        </Dropdown>
      </div>

      {(resourcedId && !!resource) && (
        <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/partners/Resources">Home</a>
        </Breadcrumb.Item>
          {resource.crumbs.map((crumb: any) => (
            <Breadcrumb.Item>
              <a href={`/resources?selected=${crumb.id}`}>{crumb.name}</a>
            </Breadcrumb.Item>
          ))}
          <Breadcrumb.Item>{resource.name}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <br />

      <List
        style={{ width: '100%' }}
        loading={loadingResources}
        grid={{ gutter: 16, column: 4 }}
        dataSource={resources}
        renderItem={(resource: any) => (
          <List.Item>
            <Card
              style={{ minWidth: 150 }}
              // cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
              actions={[
                <Tooltip title="edit">
                  <EditOutlined
                    onClick={() => {
                      setModalState({ opened: true, action: 'edit', type: resource.type });
                      setSelectedResource(resource);
                    }}
                  />
                </Tooltip>,
                <Tooltip title={resource.type === 'file' ? 'open file' : 'open folder'}>
                  {resource.type === 'file' ? (
                    <a href={resource.url} target="_blank" rel="noreferrer">
                      <EyeOutlined />
                    </a>
                  ) : (
                    <FolderOpenOutlined
                      onClick={() => navigate(`/resources?selected=${resource.id}`)}
                    />
                  )}
                </Tooltip>,
                <Tooltip title="more actions">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="0">
                          <div
                            // onClick={() => handleDelete(resource.id)}
                            style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }}
                          >
                            <EyeOutlined /> View on BP
                          </div>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <div
                            onClick={() => handleDelete(resource)}
                            style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center' }}
                          >
                            <DeleteOutlined /> Delete
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <EllipsisOutlined key="ellipsis" />
                  </Dropdown>
                </Tooltip>
              ]}
            >
              <Card.Meta title={resource.name} description="" />
            </Card>
          </List.Item>
        )}
      />

      <Modal
        title={`${modalState.action} ${modalState.type}`}
        visible={modalState.opened}
        // onOk={() => setIsModalOpen(false)}
        onCancel={() => setModalState({...modalState, opened: false})}
        footer={null}
        width={620}
      >
        <div style={{ flex: 1, width: '100%' }}>
          {modalState.type === 'file' ? (
            <>
            <Upload.Dragger
              multiple
              action=""
              onChange={(info) => {
                const { status } = info.file;
                if (status !== 'uploading') {
                  console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
              onDrop={(e) => {
                console.log('Dropped files', e.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <br/><br/>
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
                <br/><br/><br/>
              </p>
            </Upload.Dragger>
            </>
          ) : (
            <Form
              ref={formRef}
              style={{ width: '100%', flex: 1 }}
              name="basic"
              initialValues={{ name: selectedResource?.name, url: selectedResource?.url }}
              onFinish={handleModalSave}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Resource name"
                name="name"
                rules={[{ required: true, message: 'Please input the resource name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Url"
                name="url"
                rules={[{ required: false, message: 'Please input the url for the resource' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </Modal>

    </Container>
  )
}

export default Resources;
