import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { Calendar, Input, PageHeader } from "antd";
import moment from "moment";

import usePaginatedRecordList from "../hooks/usePaginatedRecordList";
import RecordDrawer from "../components/RecordDrawer";
import useQuery from "../hooks/useQuery";
import { Identifiable, RecordModel } from "../types/core";
import useCustomCalendar from "../hooks/useCustomCalendar";
import RecordSelect from "./fields/RecordSelect";
import { IComment, UnitModel } from "../types/models";
import { ancanaApi } from "../api/ancanaApi";

const Container = styled.div`
  height: 100%;

  .ant-picker-cell {
    cursor: default;
  }
`;

interface RecordCalendarViewProps<RecordType> {
  recordModel: RecordModel<RecordType>;
  customRecordName?: string;
}

function RecordCalendarView<RecordType extends Identifiable>(props: RecordCalendarViewProps<RecordType>) {
  const { query, handleSearch } = useQuery();
  const { recordModel } = props;
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList,
    recordFields,
    firstFetch
  } = usePaginatedRecordList<any>({
    query,
    recordPath: recordModel.path,
    extraQueryAppend: recordModel.tableQuery,
    apiService: recordModel.apiService || 'admin',
    pageSize: '40',
    initialAutoFetch: false,
    initialOptionsFetch: true,
  });
  const recordsPluralName = props.customRecordName || (recordModel.namePlural ? recordModel.namePlural : `${recordModel.name}s`);
  const dateKeys = Object.keys(recordFields || {}).filter(key => key.includes('date'));
  const [adjecentRecordId, setAdjecentRecordId] = useState<string>();
  const { headerRender, dateCellFullRender, onPanelChange, currentMonthDate } = useCustomCalendar({
    onlyMonthDays: true,
    dateCellContentRender: (date) => {
      let currentRecord = undefined;
      if (dateKeys.length === 1) {
        currentRecord = recordList.find(r => r[dateKeys[0]] === date.format('YYYY-MM-DD'));
      }
      if (dateKeys.length === 2) {
        currentRecord = recordList.find(r => date.isBetween(r[dateKeys[0]], r[dateKeys[1]], 'day', "[]"));
      }
      return (currentRecord && recordModel.toDescriptor) ? recordModel.toDescriptor(currentRecord, { date }) : null;
    },
    baseCommentsUrl: `housing-unit/${adjecentRecordId}`,
    filters: [
      <RecordSelect
        recordModel={UnitModel}
        extraFilterQuery="&published=true&has_no_owner=false"
        autoSelectFirst
        filter
        onChange={(value) => setAdjecentRecordId(value)}
      />
    ]
  });

  useEffect(() => {
    if (dateKeys.length > 0 && currentMonthDate) {
      const end = currentMonthDate.endOf('month').format('YYYY-MM-DD');
      const start = currentMonthDate.clone().startOf('month').add(-7, 'days').format('YYYY-MM-DD');
      firstFetch(undefined, `&${dateKeys[0]}_after=${start}&${dateKeys[0]}_before=${end}`, undefined, '31');
    }
  }, [currentMonthDate, recordFields]);

  return (
    <Container>
      <PageHeader
        title={recordsPluralName}
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        extra={[]}
        className="mb-md"
      />

      <Calendar
        mode="month"
        headerRender={headerRender}
        dateFullCellRender={dateCellFullRender}
        onPanelChange={onPanelChange}
      />
    </Container>
  )
}

export default RecordCalendarView;
