import React, { useState } from "react";
import { Comment, Form, Input, Tooltip } from 'antd';
import { EditOutlined, CopyOutlined, SaveOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { ancanaApi } from "../api/ancanaApi";
import moment from "moment";
import styled from "styled-components";


const Container = styled.div`
  .zoom {
    transition: transform .2s;
  }
  .zoom:hover {
    transform: scale(1.2);
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

interface FieldCompletionMessage {
  id: string;
  role: string;
  content: string;
  created_at: string;
  fieldId: string;
}

function InputMessage(props: FieldCompletionMessage) {
  const messageProps = props;
  const [copy, setCopy] = useState(0);
  const [editingMessageId, setEditingMessageId] = useState<null | string>(null);
  const isEditing = messageProps.id === editingMessageId;
  const [messageUpdated, setMessageUpdated] = useState<null | string>(null);
  const [updateMessageForm] = Form.useForm();

  const copyToClipboard = () => {
    setCopy(1);
  };

  const handleEditClick = (commentId: string) => {
    setEditingMessageId(commentId);
  };


  function updateMessage(field_id: string, message_id: string): void {
    const payload = {
      role: 'user',
      content: messageUpdated
    };
    ancanaApi.patch(`field-completions/${field_id}/messages/${message_id}/`, payload).then(response => {
      console.log(response.data);
    }).catch(err => {
      console.log(err)
    });
    setEditingMessageId(null);
    setMessageUpdated(null);
  }

  const onChangeMessage = (value: string) => {
    setMessageUpdated(value);
  };

  return (
    <Container>
      <Comment
        key={messageProps.id}
        actions={[
          <>
            {!isEditing &&
              <Tooltip key="edit-message" title="Edit message">
                <span onClick={async () => {
                  await handleEditClick(messageProps.id);
                }}>
                  <EditOutlined className="zoom" />
                </span>
              </Tooltip>
            }
            {isEditing &&
              <>
                <Tooltip key="cancel-message" title="Cancel">
                  <span onClick={() => {
                    setEditingMessageId(null);
                    setMessageUpdated(null);
                    updateMessageForm.setFieldsValue({ "message": messageProps.content });
                  }}>
                    <CloseOutlined className="zoom" />
                  </span>
                </Tooltip>
                <Tooltip key="save-message" title="Save changes">
                  <span onClick={() => { updateMessage(messageProps.fieldId, messageProps.id) }}>
                    <SaveOutlined className="zoom" />
                  </span>
                </Tooltip>
              </>
            }
            <Tooltip key="copy-message" title={copy ? 'Copied!' : 'Copy to clipboard'}>
              <span onClick={() => {
                copyToClipboard();
                navigator.clipboard.writeText(messageProps.content)
                setTimeout(() => {
                  setCopy(0);
                }, 1000);
              }}>
                {copy ? <CheckOutlined /> : <CopyOutlined className="zoom" />}
              </span>
            </Tooltip>
          </>
        ]

        }
        author={
          <span>{messageProps.role}</span>
        }
        content={
          <Form
            form={updateMessageForm}
          >
            <Form.Item
              name={"message"}>
              <Input.TextArea
                key={messageProps.id}
                disabled={!isEditing}
                rows={10}
                defaultValue={messageProps.content}
                onChange={(event) => onChangeMessage(event.target.value)}
              />
            </Form.Item>
          </Form>

        }
        datetime={
          <Tooltip title={moment(messageProps.created_at).format('LLL')}>
            <span>{moment(messageProps.created_at).fromNow()}</span>
          </Tooltip>
        }

      />
    </Container>
  )
}

export default InputMessage;
