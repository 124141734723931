import { Identifiable, RecordModel } from "../core";

export interface INotification extends Identifiable {
  title: string;
  body?: string;
}

export const NotificationModel: RecordModel<INotification> = {
  name: "Notification",
  path: "notifications",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.title,
  fieldModels: {'seen_by': 'Users'},
}
