import { Button, Drawer, Form, Input, Tooltip, DatePicker, InputNumber, Switch } from "antd";
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { ButtonShape } from "antd/lib/button/button";

const Container = styled.div`
  .ant-form-item-label > label {
    text-transform: capitalize;
  }
`;

interface FormInput {
  name: string;
  type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'textarea' | 'time' | 'url' | 'week';
  required?: boolean;
  props?: object;
}

interface RecordDrawerProps {
  recordName: string;
  actionName?: string;
  requieredTextFields?: string[];
  inputs?: FormInput[];
  handleRecordAction: any;
  recordId?: number | string;
  afterFormReset?: () => void;
  children?: ReactNode;
  tooltip?: string;
  disabled?: boolean;
  refFocus?: any;
  refDrawer?: any;
  handleValuesChange?: any;
  btnSize?: 'small' | 'middle' | 'large';
  btnType?: 'primary' | 'link' | 'default' | 'text' | 'ghost' | 'dashed';
  btnGhost?: boolean;
  btnText?: string;
  btnShape?: ButtonShape;
  btnIcon?: ReactNode;
  customButton?: ReactNode;
  onClose?: any;
  customFooter?: ReactNode;
  saveButtonText?: string;
  drawerWidth?: number;
  useMultipartFormData?: boolean;
  primaryKeyName?: string;
}

const InputComponents: {[inputName: string]: any} = {
  // date: (props: any) => <DatePicker {...props} format="LL" />,
  number: InputNumber,
  password: Input.Password,
  textarea: Input.TextArea,
  checkbox: (props: any) => <Switch {...props} style={{}} />,
  text: Input,
}

function RDrawer(props: RecordDrawerProps, ref: any) {
  const {
    recordName,
    handleRecordAction,
    requieredTextFields = [],
    inputs = [],
    actionName = 'Create',
    btnType = 'primary',
    btnSize = 'middle',
    useMultipartFormData = false,
    primaryKeyName,
  } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [form] = Form.useForm();

  const handleBtnClick = (e: any) => {
    e.stopPropagation();
    setDrawerOpen(true);
    setTimeout(() => {
      if (props.refFocus) props.refFocus.current!.focus({ cursor: 'all' });
    }, 250);
  }

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        setDrawerOpen(true);
        setTimeout(() => {
          if (props.refFocus) props.refFocus.current!.focus({ cursor: 'all' });
        }, 250);
      },
      setFieldsValue: (data: any) => {
        form.setFieldsValue(data);
      },
      getFieldsValue: form.getFieldsValue
    }
  }, []);

  return (
    <>
      <Tooltip title={props.tooltip}>
        {!!props.customButton ? (
          <div onClick={handleBtnClick}>
            {props.customButton}
          </div>
        ) : (
          <Button
            type={btnType}
            size={btnSize}
            shape={props.btnShape}
            icon={props.btnIcon}
            ghost={props.btnGhost || false}
            onClick={handleBtnClick}
            disabled={props.disabled}
          >
            {props.btnText ? props.btnText : `${actionName} ${recordName}`}
          </Button>
        )}
      </Tooltip>

      <Drawer
        title={`${actionName} ${recordName}`}
        placement="right"
        width={props.drawerWidth || 450}
        onClose={(e) => {
          e.stopPropagation();
          setDrawerOpen(false);
          props.onClose();
        }}
        visible={drawerOpen}
      >
        <Container>
        <Form
          form={form}
          style={{ width: '100%', flex: 1, height: '100%' }}
          name="basic"
          onFinish={async (rawData) => {
            let data: any = {};
            let config;
            if (useMultipartFormData) {
              data = new FormData();
              Object.entries(rawData).forEach(([k,v]: any) => {
                const isFileUrl = !!v && typeof v === 'string' && v.includes('https://');
                if (!!v && !isFileUrl) data.append(k, v);
              });
              config= {
                "headers": {
                  "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
                }
              }
            } else {
              for (const key in rawData) {
                if (Object.prototype.hasOwnProperty.call(rawData, key) && rawData[key] !== undefined) {
                  data[key] = rawData[key];
                }
              }
            }
            if (!!props.recordId) {
              await handleRecordAction(props.recordId, data, primaryKeyName, config);
            } else {
              await handleRecordAction(data, undefined, config);
            }
            form.resetFields();
            setDrawerOpen(false);
            if (props.afterFormReset) props.afterFormReset();
          }}
          autoComplete="off"
          layout="vertical"
          onValuesChange={props.handleValuesChange}
        >
          {requieredTextFields.map(fieldName => (
            <Form.Item
              label={fieldName}
              name={fieldName}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          ))}

          {props.children}

          {inputs.map(input => {
            const InputComponent = InputComponents[input.type || 'text'] || Input;
            return (
              <Form.Item
                label={input.name}
                name={input.name}
                rules={[{ required: input.required !== undefined ? input.required : true }]}
              >
                <InputComponent
                  type={input.type || 'text'}
                  style={{ width: '100%' }}
                  {...input.props}
                />
              </Form.Item>
            );
          })}

          <br/><br/><br/><br/><br/>

          {props.customFooter ? props.customFooter : (
          <div style={{ padding: '24px', backgroundColor: '#f3f3f3', margin: '0 -24px', position: 'fixed', bottom: 0, width: '100%' }}>
            <Form.Item style={{ display: 'inline-block', margin: '0 12px 0 0' }}>
              <Button type="primary" htmlType="submit">
                {props.saveButtonText ? props.saveButtonText : actionName}
              </Button>
            </Form.Item>
            <Button onClick={() => setDrawerOpen(false)}>
              Cancel
            </Button>
          </div>
          )}
        </Form>
        </Container>
      </Drawer>
    </>
  )
}

const FormDrawer = forwardRef(RDrawer);
export default FormDrawer;

