import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export type IUseQuery = {
  query: URLSearchParams;
  handleSearch: (q: string) => void;
  handleFilter: (key: string, value: string | null, onlySet?: boolean) => void;
}

function useQuery(): IUseQuery {
  const { search } = useLocation();

  const query: URLSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const handleSearch = (q: string) => {
    if (q === '') {
      query.delete('search');
    } else {
      query.set('search', q);
    }
    window.location.search = query.toString();
  }

  const handleFilter = (key: string, value: string | null, onlySet = false) => {
    if (!!value) {
      query.set(key, value);
    } else {
      query.delete(key);
    }
    if (onlySet) {
      const newUrl = window.location.href.split('?')[0] + '?' + query.toString();
      window.history.pushState({ path: newUrl }, '', newUrl)
    } else {
      window.location.search = query.toString();
    }
  }

  return {
    query,
    handleSearch,
    handleFilter,
  };
}

export default useQuery;
