import React, { useState } from "react";
import { Button, Form, Input, Upload } from "antd";
import { ancanaApi, ancanaApitoken } from "../api/ancanaApi";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import Popform from "./Popform";

function useRecordFiles( recordName: string, idKey = 'id' ) {
  const [expandedRow, setExpandedRow] = useState<number | string>('0');
  const [currentFiles, setCurrentFiles] = useState<any>([]);

  return {
    tableExpandableConfig: {
      expandRowByClick: false,
      showExpandColumn: false,
      expandedRowKeys: [expandedRow],
      onExpand: (expanded: boolean, record: any) => {
        setCurrentFiles([]);
        ancanaApi.get(`${recordName}/${record[idKey]}/files/`).then(res => {
          setCurrentFiles(res.data);
        });
      },
      expandedRowRender: (record: any) => (
        <div className="justify-around center h-center text-center" style={{ display: 'flex' }}>
          <Button
            shape="circle"
            icon={<CloseOutlined color="gray" />}
            style={{ position: 'absolute', top: 8, right: 16 }}
            onClick={() => setExpandedRow('0')}
            type="primary"
            size="small"
          />
          <Upload
            listType="picture"
            // onPreview={(file) => {console.log('onpreview', file);}}
            iconRender={(originNode: any, file: any) => {
              return (
                <Popform
                  layout="vertical"
                  placement="top"
                  title={`Edit file: ${originNode.name}`}
                  formContent={(
                    <Form.Item
                      initialValue={originNode.type}
                      label="Type"
                      name="name"
                      rules={[{ required: true, message: 'required' }]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  onSubmit={(data: any) => {
                    ancanaApi.patch(`${recordName}/${record[idKey]}/files/${originNode.uid}/`, data).then(res => {
                      // setCurrentFiles(res.data);
                    });
                  }}
                >
                  <a>Edit</a>
                </Popform>
              );
            }}
            action={`${process.env.REACT_APP_API_URL}/v1/${recordName}/${record[idKey]}/files/`}
            headers={{ 'Authorization': `Token ${ancanaApitoken}` }}
            onChange={({ file }) => {
              if (file.status !== 'removed') {
                setCurrentFiles([{
                  id: file.response ? (file.response as any).id : file.uid,
                  status: file.response ?  'done' : 'uploading',
                  file: file.response ? (file.response as any).file : `none/${file.name}?`,
                }, ...currentFiles.filter((f: any) => f.id !== file.uid)]);
              }
            }}
            fileList={currentFiles.map((cf: any) => ({
              uid: cf.id,
              name: cf.file.split('/').at(-1).split('?').at(0),
              type: cf.name,
              status: !!cf.status ? cf.status : 'done',
              percent: 100,
              response: 'Loaded', // custom error message to show
              url: cf.file,
            }))}
            onRemove={file => {
              ancanaApi.delete(`${recordName}/${record[idKey]}/files/${file.uid}/`).then(res => {
                if (res.status === 200) {
                  setCurrentFiles(currentFiles.filter((cf: any) => cf.id !== file.uid));
                }
              });
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>
      )
    },
    onExpand: (id: number | string) => {
      setCurrentFiles([]);
      ancanaApi.get(`${recordName}/${id}/files/`).then(res => {
        setCurrentFiles(res.data);
      });
      setExpandedRow(currId => currId === id ? 0 : id);
    },
    rowKeysMapper: (record: any) => ({ ...record, key: record[idKey] })
  }
}

export default useRecordFiles;
