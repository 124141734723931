import React, { useRef } from "react";
import styled from 'styled-components';
import { Button, DatePicker, PageHeader, Popconfirm, Table } from "antd";
import moment from "moment";

import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import RecordSelect from "../../components/fields/RecordSelect";
import { UnitModel } from "../../types/models/housing";
import RecordTable from "../../components/RecordTable";
import { ExpenseConceptUnitSettingsModel, InvoiceModel } from "../../types/models/accounting";
import { UnitExpenseSchedule } from "../../types/models/generics";
import GenericRelationLabel from "../../components/GenericRelationLabel";


const Container = styled.div`
  height: 100%;
`;

const getColumns = (deleteRecord: any, patchRecord: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    { title: 'Account name', dataIndex: 'name', sorter: true, key: 'id', width: 206 },
    {
      title: 'Related Object',
      render: (r: any) => <GenericRelationLabel content_type={r.content_type} object_id={r.object_id} />
    },
    {
      title: 'Pending Balance',
      dataIndex: ['balances', 'pending_balance', 'amount'],
      render: (am: number) => `${am<0 ? '-': ''}$${Math.abs(am).toLocaleString()}`,
      width: 150,
    },
    {
      title: 'Posted Balance',
      dataIndex: ['balances', 'posted_balance', 'amount'],
      render: (am: number) => `${am<0 ? '-': ''}$${Math.abs(am).toLocaleString()}`,
      width: 150,
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      sorter: true,
      render: (c: string) => moment(c).format('ll'),
      width: 125
    },
    {
      title: 'Actions', render: (r: any) => (
        <div className="row-actions">
          <Popconfirm
            title={`Permanently delete this record?`}
            onConfirm={() => deleteRecord(r.id)}
            okText="Yes"
          >
            <a>Delete</a>
          </Popconfirm>
        </div>
      ),
      width: 80
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}


function AccountBalances() {
  const { query, handleFilter } = useQuery();
  const {
    pagination,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'ledger-accounts',
    apiService: 'admin',
    pageSize: '25',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Account Balances"
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        extra={[
          <RecordTable modalView recordModel={InvoiceModel}>Invoices</RecordTable>,
          <RecordTable
            recordModel={ExpenseConceptUnitSettingsModel}
            recordsetFilters={(!!query.get('unit') ? { unit: query.get('unit') } : undefined) as any}
            modalView
          >
            Expense Defaults
          </RecordTable>,
          <RecordTable
            btnType="primary"
            recordModel={UnitExpenseSchedule}
            modalView
            recordsetFilters={(!!query.get('unit') ? { kwargs: `unit_id=${query.get('unit')}` } : undefined) as any}
          >
            Monthly Tasks
          </RecordTable>,
        ]}
        className="mb-md"
      >
        <RecordSelect
          recordModel={UnitModel}
          extraFilterQuery="&has_no_owner=false"
          autoSelectFirst
          filter
        />
        <DatePicker.MonthPicker
          defaultValue={!!query.get('created_at') ? moment(query.get('created_at')) : moment()}
          onChange={v => handleFilter('created_at', v ? v.startOf('month').format('YYYY-MM-DD') : '')}
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
      />
    </Container>
  )
}

export default AccountBalances;
