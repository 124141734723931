import { Input } from "antd";
import { useEffect } from "react";

function SlugInput(props: any) {
  const { setValue } = props;

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Input
      {...props}
      onChange={e => setValue(
        e.target.value.toLowerCase()
          .replace(/\s+/g, '-')           // Replace spaces with -
          .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
          .replace(/\-\-+/g, '-')         // Replace multiple - with single -
          .replace(/^-+/, '')             // Trim - from start of text
      )}
      onBlur={(e) => setValue(e.target.value.replace(/-$/, ''))} // Trim - from the end of text
    />
  )
}

export default SlugInput;
