import { useEffect, useState } from "react";
import RecordSelect from "./RecordSelect";
import { Identifiable, RecordFieldSchema, RecordModel } from "../../types/core";

interface RelatedFieldSelectProps<T> {
  fieldName: string | string[],
  fieldDefinition: RecordFieldSchema,
  formRef?: any,
  recordModel: RecordModel<T>,
  recordsetFilters: any,
}

const getSingularName = (pluralName: string) => {
  if (pluralName.slice(-3) === 'ies') {
    return pluralName.slice(0,-3)+'y';
  }
  if (pluralName.slice(-2) === 'es' && pluralName.slice(-3) !== 'nes' && pluralName.slice(-3) !== 'mes') {
    return pluralName.slice(0,-2);
  }
  return pluralName.slice(0,-1);
};

function RelatedFieldSelect<RecordType extends Identifiable>(props: RelatedFieldSelectProps<RecordType>): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [modelName, setModelName] = useState<string | null | undefined>();
  const [relatedModel, setRelatedModel] = useState<RecordModel<any>>();
  const [multiple, setMultiple] = useState(false);
  const { recordModel } = props;
  const leafFieldName = typeof props.fieldName === 'string' ? props.fieldName : props.fieldName[props.fieldName.length-1];

  useEffect(() => {
    const fieldModel = !!recordModel.fieldModels ? recordModel.fieldModels[leafFieldName] : undefined;
    setModelName(fieldModel);
    setLoading(true);
    let modelName = fieldModel || (
      leafFieldName
        .replace(/_([a-z])/g, (_, group1) => group1.toUpperCase())
        .replace(/^\w/, firstChar => firstChar.toUpperCase())
    )
    import('../../types/models/index').then((r: any) => {
      setLoading(false);
      let relatedModel = r[`${modelName}Model`];
      if (relatedModel === undefined && modelName.slice(-1) === 's') {   // Plural case
        relatedModel = r[`${getSingularName(modelName)}Model`];
        setMultiple(true);
      }
      setRelatedModel(relatedModel);
    });
  }, [props.fieldName]);

  return (
    <>
      {!!relatedModel && (
        <RecordSelect
          recordsetFilters={props.recordsetFilters}
          formName={props.fieldName}
          label={props.fieldDefinition.label}
          required={props.fieldDefinition.required}
          recordModel={relatedModel}
          mode={multiple ? "multiple" : undefined}
          allowManage
        />
      )}
      {loading && (
        <div>Loading model for "{props.fieldName}" field...</div>
      )}
      {(!loading && !relatedModel && modelName !== null) && (
        <div style={{ color: 'darkred' }}>Error importing model for "{props.fieldName}" field</div>
      )}
    </>
  )
}

export default RelatedFieldSelect;
