import { Identifiable, RecordModel } from "../core";

export interface IAlert extends Identifiable {
  name: string;
  instance_type: number;
  instance_field?: string;
  operator: string;
  value?: string;
  comparing_instance_tpye?: number;
  comparing_instance_field?: string;
  custom_title_template?: string;
  custom_body_template?: string;
}

export const AlertModel: RecordModel<IAlert> = {
  name: "Alert",
  path: "alerts",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.name,
  fieldModels: {
    'instance_type': 'ContentType',
    'comparing_instance_type': 'ContentType',
  },
}
