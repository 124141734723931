
export const localizePrice = (price: any, currency: any, shortLang: any) => {
  let convertedPrice = price;
  let convertedCurrency = currency;
  if (shortLang === 'en' && currency === 'MXN') {
    convertedPrice = price / 21;
    convertedCurrency = 'USD';
  }
  //if (shortLang === 'es' && currency === 'USD') {
  //  convertedPrice = price * 21;
  //  convertedCurrency = 'MXN';
  //}
  return `$${Math.ceil(convertedPrice).toLocaleString('en-US')} ${convertedCurrency}`
}

export const localizeArea = (squareMts: any, shortLang: any) =>
  `${Math.trunc(shortLang === 'es' ? squareMts : squareMts * 10.7639).toLocaleString('en-US')} ${shortLang === 'es' ? 'm' : 'ft'}²`


export const prettyPrintJson = (json: string) => {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
  const replacer = function(match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) {
    let key = '<span class="json-key" style="color: #FFF">',
      val = '<span class="json-value" style="color: #F92772">',
      str = '<span class="json-string" style="color: #E6DB74">',
      r = pIndent || '';
    if (pKey)
      r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
    if (pVal)
      r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
    return r + (pEnd || '');
  };

  return JSON.stringify(json, null, 4)
    .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;').replace(/>/g, '&gt;')
    .replace(jsonLine, replacer);
}

export const formatServerText = (text: string | null) => {
  return !!text ? text[0] + text.slice(1).toLowerCase().replace(/_/g, ' ') : '';
}

export const debounce = (func: Function, timeout = 100) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function isLightBg(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16);

    if ((red * 0.299 + green * 0.587 + blue * 0.114) > 150) {
      return true;
    } else {
      return false
    }
  }
  return true;
}

export function financeAmtFormat(quantity: number) {
  const quantityNum = Number(quantity);
  if (quantityNum >= 0) {
    return `$${quantityNum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

  } else {
    return `($${(quantityNum*-1).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})`;
  }
}
