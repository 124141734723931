import React, { useEffect, useRef } from "react";

import { Input, Select, Table, message, Popconfirm, Button, PageHeader, Form } from 'antd';
import styled from "styled-components";
import { Link } from "react-router-dom";

// import { formatServerText } from "../../utils";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";
import { dataApi } from "../../api/dataApi";

import dayjs from "dayjs";
import Popform from "../../components/Popform";
import FormDrawer from "../../components/FormDrawer";
import RecordSelect from "../../components/fields/RecordSelect";
import { CompanyModel } from "../../types/models/partners";
const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const { Search } = Input;
const { Option } = Select;

const Container = styled.div`
`;

const handleApproval = (brokerSecret: any) => {
  dataApi.post(`agents/approve/`, {secret: brokerSecret});
  window.location.reload();
}

const allColsSettings = { ellipsis: true }

const getColumns = (doRecordAction: any) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: 175,
      fixed: 'left',
      // render: (text: string, c: any) => <Link to={`/crm/contacts?agent_id=${c.id}`}>{c.name}</Link>
      render: (text: string, c: any) => <Link to={`/brokers/${c.id}`}>{c.name}</Link>
    },
    { title: 'Email', dataIndex: 'email', sorter: true, key: 'email', width: 175 },
    { title: 'Phone', dataIndex: 'phone', sorter: true, key: 'phone', width: 110 },
    { title: 'Company', dataIndex: 'parent_company_name', sorter: true, key: 'parent_company_name', width: 85 },
    {
      title: 'Approved',
      dataIndex: 'is_approved',
      sorter: true,
      key: 'is_approved',
      width: 65,
      render: (approved: boolean) => approved ? 'Yes' : 'No'
    },
    { title: 'Leads', dataIndex: 'lead_count', key: 'lead_count', width: 50 },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      width: 125,
      render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>
    },
    {
      title: 'Actions',
      width: 150,
      render: (txt: any, obj: any) => {
        return (
          <div className="row-actions">
            <Popconfirm
              title={`Approve ${obj.name}`}
              onConfirm={() => handleApproval(obj.secret)}
              okText="Yes"
            >
              <a href="src/pages#">Approve</a>
            </Popconfirm>

            <Popform
              placement="left"
              title={`Set a New Password for: ${obj.name}`}
              formContent={(
                <>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Password required' }]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm"
                    name="password_confirm"
                    rules={[
                      { required: true, message: 'Password confirm required' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          return (!value || getFieldValue('password') === value) ? Promise.resolve() : Promise.reject(new Error('Passwords don\'t match!'))
                        }
                      })
                    ]}>
                    <Input.Password />
                  </Form.Item>
                </>
              )}
              onSubmit={(data: any) => {
                doRecordAction('set_password', 'post', { secret: obj.secret, new_password: data.password });
              }}
            >
              <a>Password</a>
            </Popform>

            <a
              onClick={() => {
                message.success(`Copied access link for "${obj.name}"`);
                navigator.clipboard.writeText(`https://ancana.co/brokers?secret=${obj.secret}&action=setup`);
              }}
              href="#"
            >
              Copy link
            </a>
          </div>
        )
      }
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

function Brokers() {
  const { query, handleSearch, handleFilter } = useQuery();
  const {
    doRecordAction,
    pagination,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'agents',
    extraQueryAppend: '&profile=Agent&ordering=-created_at',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);

  return (
    <Container ref={elementRef} style={{ height: '100%' }}>
      <PageHeader
        title="Brokers"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <Button
            type="default"
            href="/crm/contacts?agent_null=false"
          >
            View leads
          </Button>,
          <FormDrawer
            recordName="Broker"
            handleRecordAction={(data: any) => doRecordAction('register', 'post', data)}
            requieredTextFields={['name', 'email', 'phone']}
          >
            <RecordSelect recordModel={CompanyModel} />
          </FormDrawer>
        ]}
        className="mb-md"
      >
        <Search
          placeholder="Search name, email, phone..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <Select
          allowClear
          showSearch
          placeholder="Approval status"
          optionFilterProp="children"
          onChange={(value) => handleFilter('is_approved', value)}
          defaultValue={query.get('is_approved')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="true">Approved</Option>
          <Option value="false">Not Approved</Option>
        </Select>
      </PageHeader>

      <Table
        columns={getColumns(doRecordAction)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="small"
      />
    </Container>
  )
}

export default Brokers;
