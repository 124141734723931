import styled from 'styled-components';
import { Collapse, Card, Descriptions, PageHeader, Spin, Button, Dropdown, Popconfirm, Tabs } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dataApi } from "../../api/dataApi";
import HubSidedContent from "../../components/HubSidedContent";
import FormDrawer from "../../components/FormDrawer";
import moment from "moment";
import CodeView from "../../components/CodeView";

const { Panel } = Collapse;

const Container = styled.div`
  height: 100%;
`;

function BrokerDetails() {
  const { broker_id } = useParams();
  const [broker, setBroker] = useState<any>();
  const [leads, setLeads] = useState([]);
  const [contact, setContact] = useState<any>();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [parentCompany, setParentCompany] = useState<any>();

  useEffect(() => {
    dataApi.get(`agents/${broker_id}/`).then(res => {
      setBroker(res.data);
    })
    dataApi.get(`contacts/?agent_id=${broker_id}`).then(res => {
      setLeads(res.data.results);
    });
  }, []);

  useEffect(() => {
    if (!!broker) {
      setLoadingEvents(true);
      dataApi.get(`contacts/?search=${broker.email}`).then(res => {
        if (res.data.count > 0) {
          setContact(res.data.results[0]);
        }
        setLoadingEvents(false);
      });
      dataApi.get(`companies/${broker.parent_company}`).then(res => {
        console.log('res comp', res);
        setParentCompany(res.data);
      });
    }
  }, [broker]);

  useEffect(() => {
    if (!!contact) {
      setLoadingEvents(true);
      dataApi.get(`contact-events/?contact=${contact.id}&page_size=100`).then(res => {
        setEvents(res.data.results);
        setLoadingEvents(false);
      });
    }
  }, [contact]);

  return (
    <Container>
      <HubSidedContent
        leftContent={(
          <>
            <PageHeader
              onBack={() => window.history.back()}
              title={broker?.name}
              subTitle={broker?.email}
              className="pd-md"
            />
            <Descriptions
              layout="horizontal"
              column={1}
              bordered
            >
              <Descriptions.Item label="Phone">{broker?.phone}</Descriptions.Item>
              <Descriptions.Item label="Approved">{broker?.is_approved.toString()}</Descriptions.Item>
              <Descriptions.Item label="Created">{broker?.created_at}</Descriptions.Item>
              <Descriptions.Item label="Leads">{broker?.lead_count}</Descriptions.Item>
            </Descriptions>
          </>
        )}
        rightContent={(
          <Collapse
            defaultActiveKey={['parent_company', 'contacts', 'meetings']}
            onChange={() => {}}
          >
            <Panel header="Parent Company" key="parent_company">
              {parentCompany && (
                <Card>
                  <a href={`/companies?search=${parentCompany.email}`}>{parentCompany.name}</a>
                  <div>{parentCompany.email}</div>
                  <div>{parentCompany.phone}</div>
                </Card>
              )}
            </Panel>
            <Panel
              header={`Contacts (${leads.length})`}
              extra={(
                <FormDrawer
                  recordName="contact"
                  handleRecordAction={() => {}}
                  btnText="+ Add"
                  btnSize="small"
                  btnType="link"
                />
              )}
              key="contacts"
            >
              {leads.map((lead: any) => (
                <Card>
                  <a className="fw-600" href={`/crm/contacts/${lead.id}`}>{lead.first_name} {lead.last_name}</a>
                  <div>{lead.email}</div>
                  <div>{lead.phone}</div>
                  <Dropdown
                    arrow
                    overlay={(<div className="white-bg shadow-1 pd-sm" style={{ display: 'flex', flexDirection: 'column' }}>
                      <Popconfirm
                        title={`Remove association between broker and lead?`}
                        onConfirm={() => {}}
                        okText="Yes"
                      >
                        <a>Delete</a>
                      </Popconfirm>
                    </div>)}
                    trigger={["click"]}
                  >
                    <Button type="link" shape="circle" style={{ position: 'absolute', top: 0, right: 0 }}>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Card>
              ))}
            </Panel>
            <Panel header="Meetings (0)" key="meetings">
              <p>Meetings will go here</p>
            </Panel>
          </Collapse>
        )}
      >
        <div className="fs-3 fw-500">Activity</div>
        <Tabs>
          <Tabs.TabPane tab="All Events" key="item-1">
            {events.map((evt: any) => (
              <Card
                title={`${evt.data.object_type} ${evt.data.metric} - ${evt.data.data.subject}`}
                extra={moment(evt.created_at).format('LLL')}
                style={{ textTransform: 'capitalize' }}
              />
            ))}
            {loadingEvents && (<div className="full-width pd-lg" style={{ textAlign: 'center' }}><Spin /></div>)}
            {!loadingEvents && <Card title="Broker Created" extra={moment(broker?.created_at).format('LLL')} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inspect data" key="item-2">
            Broker
            <CodeView jsonString={broker} />
            Contact
            <CodeView jsonString={contact} />
          </Tabs.TabPane>
        </Tabs>
      </HubSidedContent>
    </Container>
  );
}

export default BrokerDetails;
