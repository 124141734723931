import styled from "styled-components";
import { prettyPrintJson } from "../utils";

const Container = styled.pre`
  color: white;
  background-color: #272822;
  border: 1px solid silver;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  max-height: 500px;
  font-size: 12px;
`;

function CodeView(props: { jsonString?: string }) {
  return (
    <Container>
      <code dangerouslySetInnerHTML={{ __html: prettyPrintJson(props.jsonString || '') }} />
    </Container>
  )
}

export default CodeView;
