import { useState, useEffect, useRef } from 'react';
import { List, Card, Input, Select, Skeleton, Divider, Radio, Button, Table, PageHeader } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ancanaApi } from "../../api/ancanaApi";
import useQuery from "../../hooks/useQuery";
import ListingPreview from "../../components/ListingPreview";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";

const { Search } = Input;
const { Option } = Select;

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const allColsSettings = { ellipsis: true }
const columns = [
  {
    title: 'Name',
    sorter: true,
    width: 100,
    fixed: 'left',
    render: (obj: any) => <a href={`listings/${obj.slug}`}>{obj.name}</a>
  },
  { title: 'Condo', dataIndex: 'condo_name', sorter: true, key: 'condo_name', width: 150 },
  { title: 'Published', dataIndex: 'published', sorter: false, key: 'published', width: 50, render: (t: any, o: any) => `${o.published.toString()}` },
  { title: 'Price', dataIndex: 'price', sorter: true, key: 'price', width: 175, render: (text: string) => `$${text.toLocaleString()}` },
  { title: 'Actions', width: 120, render: (txt: any, obj: any) => (<div></div>)}
].map((e: any) => ({ ...e, ...allColsSettings }));


function Listings2() {
  const [viewType, setViewType] = useState<'cards' | 'table'>('table');
  const [locations, setLocations] = useState([]);
  const [currencies, setCurrencies] = useState<{iso: string}[]>([]);
  const { query, handleSearch, handleFilter } = useQuery();
  const {
    firstFetch,
    pagination,
    handleChange,
    fetchNextPage,
    isLoading,
    recordList: listings
  } = usePaginatedRecordList<any>({ query, recordPath: 'houses/admin-previews', apiService: 'admin', pageSize: '9'});
  const elementRef: any = useRef(null);


  useEffect(() => {
    if (viewType === 'cards') {
      fetchNextPage();
    } else {
      firstFetch();
    }

    ancanaApi.get('common_locations/?page_size=50').then(res => {
      setLocations(res.data.results);
    });
    ancanaApi.get('currencies/?page_size=50').then(res => {
      setCurrencies(res.data.results);
    })
  }, []);

  return (
    <div style={{ height: '100%' }} ref={elementRef}>
      <PageHeader
        title="Listings2"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <Button type="primary" disabled style={{ float: 'right' }}>
            Create Listing
          </Button>
        ]}
        className="mb-md"
      >
        <div style={{ width: 340 }}>
          <Search
            placeholder="Search..."
            onSearch={handleSearch}
            defaultValue={query.get('search') || ''}
            allowClear
          />
        </div>

        <Select
          allowClear
          showSearch
          placeholder="Location"
          optionFilterProp="children"
          onChange={(value) => handleFilter('location', value)}
          defaultValue={query.get('location')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: 175 }}
        >
          {locations.map((location: any) => (
            <Option value={location.id}>{location.name}</Option>
          ))}
        </Select>

        <Select
          allowClear
          showSearch
          placeholder="Has files"
          optionFilterProp="children"
          onChange={(value) => handleFilter('media_type', value)}
          defaultValue={query.get('media_type')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: 125 }}
        >
          <Option value="blueprint">blueprint</Option>
          <Option value="brochure">brochure</Option>
          <Option value="matterport">matterport</Option>
        </Select>

        <Radio.Group
          style={{ marginLeft: 'auto' }}
          onChange={(v: any)=>setViewType(v.target.value)}
          defaultValue={viewType}
        >
          <Radio.Button value="cards">
            <AppstoreOutlined />
          </Radio.Button>
          <Radio.Button value="table">
            <UnorderedListOutlined />
          </Radio.Button>
        </Radio.Group>
      </PageHeader>

      {viewType === 'cards' && (
        <div id="cards-container" style={{ position: 'absolute', height: 'calc(100% - 104px)', overflowY: 'scroll', width: '100%' }}>
          <InfiniteScroll
            dataLength={listings.length}
            next={fetchNextPage}
            hasMore={listings.length === 0  || listings.length < pagination.total}
            //hasMore={true}
            loader={(
              <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={['', '', '', '']}
                renderItem={() => (
                  <List.Item>
                    <Card>
                      <Skeleton.Image />
                      <Skeleton paragraph={{ rows: 1 }} title active />
                    </Card>
                  </List.Item>
                )}
              />
            )}
            endMessage={<Divider plain>That's all, folks!</Divider>}
            scrollableTarget="cards-container"
          >
            <List
              loading={isLoading}
              grid={{ gutter: 16, column: 4 }}
              dataSource={listings}
              renderItem={(listing: any) => (
                <List.Item>
                  <ListingPreview listing={listing} currencies={currencies} />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      )}

      {viewType === 'table' && (
        <Table
          columns={columns}
          dataSource={listings}
          pagination={pagination}
          loading={isLoading}
          onChange={handleChange}
          scroll={{ y: elementRef.current?.clientHeight - 206 }}
          size="large"
        />
      )}
    </div>
  )
}

export default Listings2;
