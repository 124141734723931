import { RecordModel } from "../core";

export interface ICompany {
  id: string;
  name: string;
  email?: string;
  phone?: string;
  city?: string;
  state?: string;
}

export interface IQuiz {
  id: string;
  name: string;
  description?: string;
}

export interface IQuizQuestion {
  id: number;
  quiz: string;
  title: string;
  description?: string;
}

export interface IQuizOption {
  id: number;
  question: number;
  name: string;
  is_correct: boolean;
}

export const CompanyModel: RecordModel<ICompany> = {
  name: "Company",
  path: "companies",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r: ICompany): string => r.name,
  apiService: "data"
}

export const QuizModel: RecordModel<ICompany> = {
  name: "Quiz",
  path: "companies",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r: ICompany): string => r.name,
  apiService: "data"
}
