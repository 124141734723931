import styled from 'styled-components';

import { PageHeader, Button, Descriptions, Badge } from 'antd';
import { useEffect, useState } from "react";
import { ancanaApi } from "../../api/ancanaApi";
import { useParams } from "react-router-dom";


const Container = styled.div`
`;

function Listing({  }) {
  const { listing_id } = useParams();
  const [listing, setListing] = useState<any>();

  useEffect(() => {
    ancanaApi.get(`houses/${listing_id}`).then(res => {
      console.log('>>>', res);
      setListing(res.data);
    });
  }, []);

  return (
    <Container>
      <PageHeader
        title="Listing"
        subTitle="This is a subtitle"
        extra={[
          <Button key="3">Operation</Button>,
          <Button key="2">Operation</Button>,
          <Button key="1" type="primary">
            Primary
          </Button>,
        ]}
      />

      <br />
      <br />

      <Descriptions bordered size="middle">
        <Descriptions.Item label="ID" span={3}>{listing?.id}</Descriptions.Item>
        <Descriptions.Item label="Name">{listing?.name}</Descriptions.Item>
        <Descriptions.Item label="Condo Name">{listing?.condo.name}</Descriptions.Item>
        <Descriptions.Item label="Characteristics">{listing?.condo.name}</Descriptions.Item>
      </Descriptions>
    </Container>
  )
}

export default Listing;
