import { Identifiable, RecordModel } from "../core";
import { UnitModel, IUnit, ListingModel, OwnerModel } from "./housing";
import RecordSelect from "../../components/fields/RecordSelect";
import { Tag } from "antd";
import { SketchOutlined, SyncOutlined } from '@ant-design/icons';
import YearSelect from "../../components/fields/YearSelect";
import React from "react";
import { OwnerType } from "../coreModels";
import BookingPreview from "../../components/business/BookingPreview";


export interface IBooking extends Identifiable {
  unit: IUnit;
  unit_owner?: OwnerType;
  start_date: string;
  end_date: string;
  is_preferred: boolean;
  for_rent: boolean;
  round: number;
  data?: {
    assignment: {
      rank: number; week: number; round: number; fraction: number; preferred: boolean;
    }
  }
}

export const BookingModel: RecordModel<IBooking> = {
  name: "Booking",
  path: "bookings",
  selectQuery: "",
  tableQuery: "&expand=unit,unit_owner.owner",
  toDescriptor: (r, extraProps) => <BookingPreview {...extraProps} booking={r} />,
  toString: r => r.start_date,
  contextActions: [
    { name: 'Add comment', path: 'comments' },
  ]
}

export interface IBookingRound extends Identifiable {
  year: number;
  unit: number | IUnit;
  weekday_start: number;
  start_index: number;
  start_date: string;
  end_date: string;
  active: boolean;
  weeks_required: number;
  usage_date_start: string;
  usage_date_end: string;
  closed: boolean;
}

export const BookingRoundModel: RecordModel<IBookingRound> = {
  name: "Booking Round",
  path: "booking-rounds",
  selectQuery: "&expand=unit.name&fields=id,unit.name,year",
  tableQuery: "&expand=unit&fields=*,unit.name,unit.id",
  toString(r: IBookingRound): string {
    const unitStr = typeof r.unit === 'number' ? r.unit.toString() : r.unit.name;
    return `${unitStr} - ${r.year}`;
  },
  detailAction: true,
  customTableMappings: {
    usage_date_start: { omit: true },
    usage_date_end: { omit: true },
    unit: { path: ['unit', 'name'], width: 200 },
    active: { width: 100 },
  },
  tableFilters: [
    { selectModel: UnitModel },
    { component: () => <YearSelect filter /> },
  ],
  /*
  crossTableQuery: '&expand=unit&fields=*,unit.name,unit.id',
  crossRecordViews: [
    { toModel: OwnerModel, toExtraQuery: '&expand=unit,owner&fields=id,owner,unit', invertedRel: true, relName: 'owners' }
  ],
  */
}

export interface ISpecialStay extends Identifiable {
  units: (number | IUnit)[];
  name: string;
  checkin: string;
  days: number;
  type: string;
}

export const SpecialStayModel: RecordModel<ISpecialStay> = {
  name: "Special Stay",
  path: "special-stays",
  selectQuery: "",
  tableQuery: "&expand=units&fields=*,units.name,units.id",
  toString(r: ISpecialStay): string {
    return `${r.name} - ${r.type}`;
  },
  icon: <SketchOutlined />,
  customCreateFields: [
    { name: "units", component: (props) => <RecordSelect {...props} recordModel={UnitModel} mode="multiple" formName="units" /> },
  ],
  customTableMappings: {
    units: {
      render: (
        r => (
          <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 4 }}>
            {r.units.length === 0 && <Tag color="blue">All units</Tag>}
            {r.units.map(u => <Tag style={{ letterSpacing: '-0.5px' }}>{typeof u === 'number' ? u.toString() : u.name}</Tag>)}
          </div>
        )
      )
    },
  },
  tableFilters: [
    { selectModel: UnitModel },
    { selectField: 'type' },
    { component: (q, props) => <YearSelect filter {...props} /> },
  ],
  crossTableQuery: '&fields=id,name,units',
  crossRecordViews: [
    { toModel: UnitModel, toExtraQuery: '&fields=id,name', invertedRel: true, relName: 'units' }
  ],
}

export interface ILodgifyProperty extends Identifiable {
  lodgify_id: number;
  name: string;
  subscription_plans: string[];
  latitude: string;
  longitud: string;
  created_at: string;
  modified_at: string;
}

export const LodgifyPropertyModel: RecordModel<ILodgifyProperty> = {
  name: "Lodgify Property",
  namePlural: "Lodgify Properties",
  path: "lodgify-properties",
  selectQuery: "",
  tableQuery: "",
  toString: r => r.name,
  customTableMappings: {
    name: { width: '25%' },
    subscription_plans: { render: r => r.subscription_plans.join(', ') }
  },
  customAction: {
    name: 'Reload from lodgify',
    path: 'sync',
    icon: <SyncOutlined />,
    isDetail: false
  }
}
