import { Identifiable, RecordModel } from "../core";
import { IContentType } from "./generics";
import { DiffOutlined } from '@ant-design/icons';
import RecordSelect from "../../components/fields/RecordSelect";
import { ConceptModel, IExpenseConcept } from "./accounting";
import GenericRecordSelect from "../../components/fields/GenericRecordSelect";


export interface IAccountModelSimple extends Identifiable {
  code: string;
  many: boolean;
  content_type: number;
  ledger_content_type: number;
}

export interface IAccountModel extends Identifiable {
  code: string;
  many: boolean;
  content_type: IContentType;
  ledger_content_type: IContentType;
}

export const AccountModelModel: RecordModel<IAccountModel> = {
  name: "Account Model",
  path: "account-models",
  selectQuery: "&expand=content_type",
  tableQuery: "&expand=content_type,ledger_content_type",
  toString: r => `${!!r.content_type ? r.content_type.model : ''} ${r.code}`,
  fieldModels: {
    ledger_content_type: 'ContentType'
  },
  customTableMappings: {
    normal_balance: { omit: true }
  }
}

export interface ITransactionModel extends Identifiable {
  code: string;
  name: string;
  description?: string;
  concept: IExpenseConcept;
  ledger_content_type: IContentType;
  debited_acc_model: IAccountModel;
  credited_acc_model: IAccountModel;
  secondary_debited_acc_model?: IAccountModel;
  secondary_credited_acc_model?: IAccountModel;
}

export const TransactionModelModel: RecordModel<ITransactionModel> = {
  name: "Transaction Model",
  path: "transaction-models",
  selectQuery: "&expand=ledger_content_type",
  tableQuery: "&expand=ledger_content_type,debited_acc_model.content_type,credited_acc_model.content_type,concept,secondary_debited_acc_model.content_type,secondary_credited_acc_model.content_type",
  toString: r => `${r.concept?.code}: ${r.name}`,
  fieldModels: {
    ledger_content_type: 'ContentType',
    debited_acc_model: 'AccountModel',
    credited_acc_model: 'AccountModel',
    secondary_debited_acc_model: 'AccountModel',
    secondary_credited_acc_model: 'AccountModel',
  },
  customCreateFields: [
    { name: 'concept', component: (props) => <RecordSelect formName="concept" required={false} recordModel={ConceptModel} /> },
    { name: 'ledger_obj_id', component: (props) => (
        <GenericRecordSelect
          {...props}
          contentType={`${props.record?.ledger_content_type?.app_label}_${props.record?.ledger_content_type?.model}`}
          objectIdFormName="ledger_obj_id"
          hideContentTypeSelect
        />
      )
    },
    { name: 'debited_obj_id', component: (props) => (props.record?.debited_acc_model?.content_type.model !== props.record?.ledger_content_type.model) ? (
        <GenericRecordSelect
          {...props}
          contentType={`${props.record?.debited_acc_model?.content_type?.app_label}_${props.record?.debited_acc_model?.content_type?.model}`}
          objectIdFormName="debited_obj_id"
          hideContentTypeSelect
        />
      ) : null
    },
    { name: 'credited_obj_id', component: (props) => (props.record?.credited_acc_model?.content_type.model !== props.record?.ledger_content_type.model) ? (
        <GenericRecordSelect
          {...props}
          contentType={`${props.record?.credited_acc_model?.content_type?.app_label}_${props.record?.credited_acc_model?.content_type?.model}`}
          objectIdFormName="credited_obj_id"
          hideContentTypeSelect
        />
      ) : null
    },
    /*
    { name: 'secondary_debited_obj_id', component: (props) => !!props.record?.secondary_debited_acc_model?.content_type && (props.record?.secondary_debited_acc_model?.content_type.model !== props.record?.ledger_content_type.model) ? (
        <GenericRecordSelect
          {...props}
          contentType={`${props.record?.secondary_debited_acc_model?.content_type?.app_label}_${props.record?.secondary_debited_acc_model?.content_type?.model}`}
          objectIdFormName="secondary_debited_obj_id"
          hideContentTypeSelect
        />
      ) : null
    },
    { name: 'secondary_credited_obj_id', component: (props) => !!props.record?.secondary_credited_acc_model && (props.record?.secondary_credited_acc_model?.content_type.model !== props.record?.ledger_content_type.model) ? (
        <GenericRecordSelect
          {...props}
          contentType={`${props.record?.secondary_credited_acc_model?.content_type?.app_label}_${props.record?.secondary_credited_acc_model?.content_type?.model}`}
          objectIdFormName="secondary_credited_obj_id"
          hideContentTypeSelect
        />
      ) : null
    },
     */
  ],
  customTableMappings: {
    name: { render: r => <div><div style={{ fontFamily: 'monospace', fontSize: 11, fontWeight: 600 }}>{r.code}</div>{r.concept?.code} {r.name}</div>, title: 'Code / Name', width: 320 },
    code: { omit: true }, concept: { omit: true }, ledger_content_type: { omit: true }, description: { omit: true },
    secondary_percentage: { omit: true }, mirror_secondary_transactions: { omit: true }, monthly_log: { omit: true }
  },
  customAction: {
    name: "Log",
    path: 'log',
    isDetail: true,
    method: 'post',
    icon: <DiffOutlined />
  },
  adjacentModels: [AccountModelModel],
}
