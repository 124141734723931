import { dataApi } from "../api/dataApi";
import styled from "styled-components";
import RecordTimeLGraph from "../components/RecordTimeLGraph";
import { ContactEventModel, ContactModel } from "../types/models/crm";

// var ReactS3Uploader = require('react-s3-uploader');

/*
            "http://*.ancana.co",
            "https://*.ancana.co",
            "https://*.ancana.mx",
            "http://*.ancana.mx",
 */

const getSignedUrl = (file: any, callback: any) => {
  const params = {
    objectName: file.name,
    contentType: file.type
  };

  dataApi.get('api/resources/boto_sign/', { params })
    .then((data: any) => {
      callback(data);
    })
    .catch((error: any) => {
      console.error(error);
    });
}

const Container = styled.div`
  width: 100%;
  // text-align: center;
  // margin: 10% auto;
`;

function Home() {
  return (
    <Container>
      <RecordTimeLGraph recordModel={ContactModel} />
      <RecordTimeLGraph recordModel={ContactEventModel} />
    </Container>
  )
}

export default Home;
