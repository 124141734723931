import React, { useEffect, useRef } from "react";

import { Input, Select, Table, message, Popconfirm, Button, PageHeader, Form } from 'antd';
import styled from "styled-components";
import { Link } from "react-router-dom";

// import { formatServerText } from "../../utils";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";
import { dataApi } from "../../api/dataApi";

import Popform from "../../components/Popform";
import FormDrawer from "../../components/FormDrawer";
import RecordSelect from "../../components/fields/RecordSelect";
import { CompanyModel } from "../../types/models/partners";
import moment from "moment/moment";

const { Search } = Input;
const { Option } = Select;

const Container = styled.div`
`;

const handleApproval = (brokerSecret: any) => {
  dataApi.post(`agents/approve/`, {secret: brokerSecret});
  window.location.reload();
}

const allColsSettings = { ellipsis: true }

const getColumns = (doRecordAction: any) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      // width: 175,
      fixed: 'left',
      // render: (text: string, c: any) => <Link to={`/crm/contacts?agent_id=${c.id}`}>{c.name}</Link>
      render: (text: string, c: any) => <Link to={`/brokers/${c.id}`}>{c.name}</Link>
    },
    { title: 'Email', dataIndex: 'email', sorter: true, key: 'email' },
    { title: 'Phone', dataIndex: 'phone', sorter: true, key: 'phone', width: 175 },
    // { title: 'Company', dataIndex: 'parent_company_name', sorter: true, key: 'parent_company_name', width: 85 },
    {
      title: 'Progress',
      dataIndex: ['progress', 'level'],
      render: (progress: string) => <div>{progress ? progress.split("amb_")[1].replace("_", " ") : 'registered'}</div>,
      sorter: true, key: 'is_approved', width: 125,
    },
    { title: 'Experience', dataIndex: 'experience_level', key: 'experience_level', width: 120 },
    { title: 'Zip code', dataIndex: 'zip_code', key: 'zip_code', width: 75 },
    {
      title: 'Location', dataIndex: 'zip_address', key: 'zip_address',
      render: (address: any) => address ? `${address?.city}, ${address?.state}` : '',
      width: 125
    },
    { title: 'Leads', dataIndex: 'lead_count', key: 'lead_count', width: 75 },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      // width: 175,
      render: (c: string) => moment(c).format('ll'),
      width: 120,
    },
    {
      title: 'Actions',
      width: 100,
      render: (txt: any, obj: any) => {
        return (
          <div className="row-actions">
            <Popform
              placement="left"
              title={`Set a New Password for: ${obj.name}`}
              formContent={(
                <>
                  <Form.Item label="Password" name="password"
                             rules={[{ required: true, message: 'Password required' }]}>
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label="Confirm"
                    name="password_confirm"
                    rules={[
                      { required: true, message: 'Password confirm required' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          return (!value || getFieldValue('password') === value) ? Promise.resolve() : Promise.reject(new Error('Passwords don\'t match!'))
                        }
                      })
                    ]}>
                    <Input.Password />
                  </Form.Item>
                </>
              )}
              onSubmit={(data: any) => {
                doRecordAction('set_password', 'post', { secret: obj.secret, new_password: data.password });
              }}>
              <a>Password</a>
            </Popform>
          </div>
        )
      }
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }))
}

function Ambassadors() {
  const { query, handleSearch, handleFilter } = useQuery();
  const {
    doRecordAction,
    pagination,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'agents',
    extraQueryAppend: '&profile=Ambassador&ordering=-created_at',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);

  return (
    <Container ref={elementRef} style={{ height: '100%' }}>
      <PageHeader
        title="Ambassadors"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <Button
            type="default"
            href="/crm/contacts?agent_null=false"
          >
            View leads
          </Button>
        ]}
        className="mb-md"
      >
        <Search
          placeholder="Search name, email, phone..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <Select
          allowClear
          showSearch
          placeholder="Progress"
          optionFilterProp="children"
          onChange={(value) => handleFilter('ambassadorprogress__level', value)}
          defaultValue={query.get('ambassadorprogress__level')}
          //onSearch={onSearch}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: 175 }}
        >
          <Option value="amb_registered">registered</Option>
          <Option value="amb_video_played">video played</Option>
          <Option value="amb_test_started">test started</Option>
          <Option value="amb_test_failed">test failed</Option>
          <Option value="amb_test_passed">test passed</Option>
          <Option value="amb_lead_added">lead added</Option>
          <Option value="amb_lead_qualified">lead qualified</Option>
        </Select>
      </PageHeader>

      <Table
        columns={getColumns(doRecordAction)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="small"
      />
    </Container>
  )
}

export default Ambassadors;
