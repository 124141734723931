import styled from 'styled-components';
import { useEffect, useState } from "react";
import { Tag, Descriptions, Input } from 'antd';
import { ancanaApi } from "../../api/ancanaApi";

const Container = styled.div`
`;

function Profile() {
  const [userDetails, setUserDetails] = useState<any>();

  useEffect(() => {
    ancanaApi.get(`users/me`).then(res => {
      setUserDetails(res.data);
    });
  }, []);

  return (
    <Container>
      <Descriptions
        title="My Ancana account"
        layout="horizontal"
        column={1}
        bordered
      >
        <Descriptions.Item label="ID">
          {userDetails?.id}
        </Descriptions.Item>
        <Descriptions.Item label="First Name">
          <Input bordered={false} value={userDetails?.first_name} />
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          <Input bordered={false} value={userDetails?.last_name} />
        </Descriptions.Item>
        <Descriptions.Item label="Permission Groups" contentStyle={{ width: '85%' }}>
          {userDetails?.groups.map((group: any) => (<Tag key={group.id}>{group.name}</Tag>))}
          {userDetails?.is_superuser && (<Tag color="blue">Super User</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Date joined">
          {(new Date(userDetails?.date_joined)).toDateString()}, {(new Date(userDetails?.date_joined)).toLocaleTimeString()}
        </Descriptions.Item>
      </Descriptions>
    </Container>
  )
}

export default Profile;
