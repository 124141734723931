import React, { useRef } from "react";
import styled from 'styled-components';
import { Input, PageHeader, Popconfirm, Table, Modal } from "antd";
import moment from "moment";

import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import RecordDrawer from "../../components/RecordDrawer";

const { Search } = Input;


const Container = styled.div`
  height: 100%;
`;

const getColumns = (deleteRecord: any, patchRecord: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    // { title: 'ID', dataIndex: 'id', sorter: true, key: 'id', width: 50 },
    { title: 'Contact ID', dataIndex: ['contact'], render: (id: string) => <a href={`/crm/contacts/${id}`}>{id}</a>, sorter: true, key: 'contact', width: 105 },
    {
      title: 'Values',
      render: (obj: any) => (
        <div>
          <small><b>Name:</b> {obj.data.data.form_values.name}</small><br/>
          <small><b>Email:</b> {obj.data.data.form_values.email}</small><br/>
          <small><b>Phone:</b> {obj.data.data.form_values.phone}</small>
        </div>
      ),
      sorter: true, key: 'values', width: 200
    },
    {
      title: 'Message',
      dataIndex: ['data', 'data'],
      render: (data: any) => {
        const message = data?.form_values?.message || '';
        return (
          <>
            <p className="fs-1" style={{ margin: 0, whiteSpace: 'normal' }}>
              {message.length > 175 ? (
                <>
                  {message.slice(0,175)}... <a style={{ fontSize: '124%' }} onClick={() => Modal.info({
                  title: `Message from ${data.form_values.name}`,
                  content: (
                    <div>
                      <p className="pre-line">{message}</p>
                    </div>
                  ),
                  onOk() {},
                })}>View full.</a></>
              ) : message}
            </p>
          </>
        )
      },
      sorter: true, key: 'message'
    },
    { title: 'Form', dataIndex: ['data', 'data', 'form_values', 'form_name'], sorter: true, key: 'form_name', width: 225 },
    { title: 'IP', dataIndex: ['data', 'data', 'ip_address'], sorter: true, key: 'ip', width: 140 },
    {
      title: 'Agent',
      dataIndex: ['data', 'data'],
      render: (data: any) => (
        <>
          <p className="fs-1" style={{ margin: 0, whiteSpace: 'normal' }}>
            {data.user_agent.length > 100 ? (
              <>
                {data.user_agent.slice(0,100)}... <a style={{ fontSize: '124%' }} onClick={() => Modal.info({
                title: `${data.form_values.name}'s User Agent:`,
                content: (
                  <div>
                    <p className="pre-line">{data.user_agent}</p>
                  </div>
                ),
                onOk() {},
              })}>View full.</a></>
            ) : data.user_agent}
          </p>
        </>
      ),
      sorter: true, key: 'ip', width: 250
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      sorter: true,
      render: (c: string) => moment(c).format('ll'),
      width: 125
    },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}


function FormSubmissions() {
  const { query, handleSearch } = useQuery();
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'contact-events',
    extraQueryAppend: '&cio_metric=submitted',
    // apiService: 'admin',
    pageSize: '25',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Form Submissions"
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        className="mb-md"
      >
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
      />
    </Container>
  )
}

export default FormSubmissions;
