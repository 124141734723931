import React, { useRef } from "react";
import styled from 'styled-components';
import { Input, PageHeader, Popconfirm, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import moment from "moment";

import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import FormDrawer from "../../components/FormDrawer";
import RecordSelect from "../../components/fields/RecordSelect";
import { UnitModel, IUnit } from "../../types/models/housing";

const { Search } = Input;


const Container = styled.div`
  height: 100%;
`;

const getColumns = (deleteRecord: any, patchRecord: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    // { title: 'ID', dataIndex: 'id', sorter: true, key: 'id', width: 50 },
    { title: 'House - Year', render: (o: any) => <a href={`/booking-rounds/${o.id}`}>{o.unit.name} - {o.year}</a> },
    { title: 'First Pick', dataIndex: 'start_index', width: 110 },
    { title: 'Weeks Picked', dataIndex: 'weeks_required', width: 110 },
    { title: 'Start Date', dataIndex: 'start_date', sorter: true, render: (c: string) => moment(c).format('ll'), width: 150 },
    { title: 'End Date', dataIndex: 'end_date', sorter: true, render: (c: string) => moment(c).format('ll'), width: 150 },
    { title: 'Active', dataIndex: 'active', sorter: true, render: (active: boolean) => active ? <CheckCircleTwoTone twoToneColor="limegreen" /> : <CloseCircleTwoTone twoToneColor="red" />, width: 85 },
    { title: 'Week Start', dataIndex: 'weekday_start', render: (ws: number) => moment().startOf('week').add(ws, 'days').format('dddd'), width: 120 },
    { title: 'Selection Range', render: (br: any) => br.usage_date_start ? <small>{moment(br.usage_date_start).format('ll')} - {moment(br.selection_date_end).format('ll')}</small> : 'All Year', width: 175 },
    {
      title: 'Actions', render: (r: any) => (
        <>
          <Popconfirm
            title={`Permanently delete this record?`}
            onConfirm={() => deleteRecord(r.id)}
            okText="Yes"
          >
            <a>Delete</a>
          </Popconfirm>
        </>
      ),
      width: 150
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}


function BookingRounds() {
  const { query, handleSearch } = useQuery();
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    recordList
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'booking-rounds',
    apiService: 'admin',
    extraQueryAppend: '&expand=unit&omit=unit.unit_ownerships,unit.data',
    pageSize: '25',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);

  return (
    <Container>
      <PageHeader
        title="Booking Rounds"
        subTitle={pagination.total?.toLocaleString('en-US') + ' records'}
        extra={[
          <FormDrawer
            recordName="Booking Round"
            handleRecordAction={createRecord}
            inputs={[
              { name: 'year', type: 'number', props: { min: 2023, max: 2123 } },
              { name: 'start_date', type: 'date' },
              { name: 'end_date', type: 'date' },
              { name: 'start_index', type: 'number' },
              { name: 'active', type: 'checkbox', required: false },
              { name: 'weekday_start', type: 'number', required: false, props: { min: 0, max: 6 } },
              { name: 'weeks_required', type: 'number', required: false, props: { min: 0, max: 24 } },
              { name: 'usage_date_start', type: 'date', required: false  },
              { name: 'usage_date_end', type: 'date', required: false },
            ]}
          >
            <RecordSelect<IUnit>
              recordModel={UnitModel}
              extraFilterQuery="&published=true&has_no_owner=false"
            />
          </FormDrawer>
        ]}
        className="mb-md"
      >
        <Search
          placeholder="Search by year or house..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <RecordSelect<IUnit>
          recordModel={UnitModel}
          extraFilterQuery="&published=true&has_no_owner=false"
          filter
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord)}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
      />
    </Container>
  )
}

export default BookingRounds;
