import RecordSelect from "./RecordSelect";
import { ContentTypeModel, ContentTypeModels } from "../../types/models/generics";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface GenericRecordSelectProps {
  formRef?: any;
  required?: boolean;
  formName?: string;
  objectIdFormName?: string;
  label?: string;
  contentType?: string;
  contentTypeKey?: string;
  hideContentTypeSelect?: boolean;
}

const Container = styled.div<any>`
  position: relative;

  ::after {
    content: "";
    position: absolute;
    display: ${({ hideContentTypeSelect }) => hideContentTypeSelect ? 'none' : 'flex'};
    width: ${({ typeSelected }) => typeSelected ? 'calc(100% + 24px)' : '100%'};
    height: calc(100% - 60px);
    bottom: 15px;
    left: ${({ typeSelected }) => typeSelected ? '-12px' : '0'};
    border: 1px dashed #CCA274;
    border-radius: 2px;
    z-index: -1;
  }

  label {
    background-color: white;
  }
`;

function GenericRecordSelect(props: GenericRecordSelectProps) {
  const [contentType, setContentType] = useState(props.contentType);
  const [contentRecords, setContentRecords] = useState<any[]>([]);
  const contentTypeKey = props.contentTypeKey || "content_type";

  const getContentType = (val: string, recordList: any[]) => {
    setContentType(undefined);
    const contentType = recordList.find(r => r.id.toString() === val);
    if (contentType) setTimeout(() => setContentType(`${contentType?.app_label}_${contentType?.model}`), 100);
  }

  useEffect(() => {
    setTimeout(() => {
      let typeId;
      try {
        typeId = props.formRef.current.getFieldsValue()[contentTypeKey];
        getContentType(typeId.toString(), []);
      } catch {}
      if (typeId) {
        getContentType(typeId.toString(), contentRecords);
      }
    }, 100);
    return () => {
      if (!props.contentType) {
        setContentType(undefined);
      }
    }
  }, [props, contentRecords]);

  return (
    <Container typeSelected={!!contentType} hideContentTypeSelect={props.hideContentTypeSelect}>
      {!props.hideContentTypeSelect &&
        <RecordSelect
          formName={props.formName || contentTypeKey}
          label={props.label || props.formName || 'Related Object Type'}
          recordModel={ContentTypeModel}
          onChange={getContentType}
          onInitialFetch={(records) => {
            setContentRecords(records);
          }}
          required={props.required}
        />
      }

      {contentType && (
        <RecordSelect
          formName={props.objectIdFormName || 'object_id'}
          label={ContentTypeModels[contentType].name}
          recordModel={ContentTypeModels[contentType]}
          required={props.required}
        />
      )}
    </Container>
  )
}

export default GenericRecordSelect;
