import styled from 'styled-components';
import { Popconfirm } from "antd";
import React, { ReactNode } from "react";
import { Identifiable, RecordModel, RecordsetFilters } from "../types/core";
import RecordDrawer from "./RecordDrawer";

const Container = styled.div`
`;

interface RecordCustomActionProps<T> {
  recordModel: RecordModel<T>;
  record: T;
  doRecordAction: any;
  recordsetFilters?: RecordsetFilters;
}

function RecordCustomAction<T extends Identifiable>(props: RecordCustomActionProps<T>) {
  const { recordModel, doRecordAction, record: r } = props;
  const method = recordModel.customAction?.method;

  const actionBtn = (
    <a style={{ opacity: (recordModel?.customAction?.disabled ? recordModel?.customAction?.disabled(r) : false) ? 0.33 : 1 }}>
      {typeof recordModel?.customAction?.icon === 'function' ? recordModel?.customAction?.icon(r) : recordModel?.customAction?.icon}
      {' '}
      {typeof recordModel?.customAction?.name === 'function' ? recordModel?.customAction?.name(r) : recordModel?.customAction?.name}
    </a>
  )

  return (
    <Container>
      {method === 'post' ? (
        <div>
          <RecordDrawer
            customButton={actionBtn}
            recordModel={recordModel}
            customAction={recordModel.customAction}
            recordInstance={r}
            customRecordName={`${recordModel.name} "${recordModel.toString(r)}"`}
            recordsetFilters={props.recordsetFilters}
          />
        </div>
      ) : (
        <Popconfirm
          title={`Perform ${recordModel.customAction?.name} now?`}
          onConfirm={() => doRecordAction(recordModel?.customAction?.path, undefined, undefined, recordModel?.customAction?.isDetail ? r.id : undefined)}
          okText="Yes"
          disabled={recordModel?.customAction?.disabled ? recordModel?.customAction?.disabled(r) : false}
        >
          {actionBtn}
        </Popconfirm>
      )}
    </Container>
  )
}

export default RecordCustomAction;
