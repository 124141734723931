import styled from 'styled-components';
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, PageHeader, Table, Popconfirm, message } from "antd";
import * as R from 'ramda';

import { ancanaApi } from "../../api/ancanaApi";
import { useParams } from "react-router-dom";
import { EditableCell, EditableRow } from "../../components/editable";
import useRecordFiles from "../../components/useRecordFiles";


const Container = styled.div`
  height: 100%;

  .numbers-cont {
    text-align: right;
    padding-right: 16px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const getColumns = (deleteRecord: any, patchRecord: any, onExpand: any, currencySymbol = '$') => {
  const allColsSettings = { ellipsis: true }
  return [
    { title: 'No.', render: (t: any, r: any, i: number) => i+1, width: 60 },
    { title: 'Description', dataIndex: 'description', sorter: true, key: 'id', editable: true },
    {
      title: 'Amount (edit in cents)',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: any) => <div className="numbers-cont">{currencySymbol}{Number(amount/100).toLocaleString()}</div>,
      width: 250,
      editable: true
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (quantity: any) => <div className="numbers-cont">{quantity}</div>,
      width: 100,
      editable: true
    },
    {
      title: 'Line total',
      render: (line: any) => <div className="numbers-cont">{currencySymbol}{Number((line.amount * line.quantity)/100).toLocaleString()}</div>,
      width: 150
    },
    {
      title: 'Actions', width: 200, render: (line: any) => (
        <div className="row-actions">
          <a onClick={() => onExpand(line.id)}>Files</a>
          <Popconfirm
            title={`Delete this line?`}
            onConfirm={() => deleteRecord(line.id)}
            okText="Yes"
          >
            <a>Delete</a>
          </Popconfirm>
        </div>
      )
    },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

function InvoiceDetail() {
  const { invoice_id } = useParams();
  const { query } = useQuery();
  const [invoice, setInvoice] = useState<any>();
  const {
    // pagination,
    handleChange,
    isLoading,
    createRecord,
    deleteRecord,
    patchRecord,
    recordList: lines
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'journal-invoice-lines',
    extraQueryAppend: `&invoice=${invoice_id}&expand=journal,currency`,
    apiService: 'admin',
    pageSize: '100',
    initialAutoFetch: true
  });
  const elementRef = useRef<any>();
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('journal-invoice-lines');

  useEffect(() => {
    ancanaApi.get(
      `journal-invoices/${invoice_id}/?expand=journal,currency&fields=id,status,journal.owner_name,billing_period,due_date,total,currency`
    ).then(res => {
      setInvoice(res.data);
    });
  }, []);


  const newEmptyLine = useCallback(() => {
    createRecord({
      invoice: invoice.id,
      description: 'concept',
      amount: 0,
    })
  }, [createRecord, invoice]);

  const handleSave = (row: any) => {
    const currentLine = lines.find(line => line.id === row.id);
    const diffObj= R.fromPairs(
      R.difference(
        R.toPairs(row).filter(rowP => rowP[0] !== 'key'),
        R.toPairs(currentLine)
      )
    )
    if (!R.isEmpty(diffObj)) patchRecord(row.id, diffObj);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = getColumns(deleteRecord, patchRecord, onExpand, invoice?.currency.symbol).map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: invoice?.status === 'draft' ? handleSave : () => message.error(`Editing is only possible when invoice is marked as "draft"`),
      }),
    };
  });

  return (
    <Container ref={elementRef}>
      <PageHeader
        onBack={() => window.history.back()}
        title={`Invoice Lines`}
        subTitle={`${invoice?.journal.owner_name}`}
        extra={[
          <Button type="primary" onClick={newEmptyLine} disabled={invoice?.status !== 'draft'}>
            Create Line
          </Button>
        ]}
        className="mb-md"
      />

      <Table
        columns={columns}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={lines.map(rowKeysMapper)}
        pagination={false}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 150 }}
        size="small"
        expandable={tableExpandableConfig}
        summary={() => (
          <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} />
            <Table.Summary.Cell index={1}>
              <div className="fw-500">Total</div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div className="numbers-cont fw-500">
                {invoice?.currency.symbol}{lines.reduce((acc, line) => acc + Number(line.amount)/100, 0).toLocaleString()}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} colSpan={2}>
              <div className="numbers-cont fw-500">
                {invoice?.currency.symbol}{lines.reduce((acc, line) => (acc + (Number(line.amount) * Number(line.quantity))/100), 0).toLocaleString()}
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </Container>
  )
}

export default InvoiceDetail;
