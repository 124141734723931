import styled from 'styled-components';
import { Button, PageHeader, Table } from "antd";
import React, { useRef } from "react";
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import FormDrawer from "../../components/FormDrawer";

const Container = styled.div`
`;


const allColsSettings = { ellipsis: true }
const columns = [
  { title: 'Name', dataIndex: 'name', sorter: true, key: 'name' },
  { title: 'Email', dataIndex: 'email', sorter: true, key: 'email' },
  { title: 'Phone', dataIndex: 'phone', sorter: true, key: 'phone' },
  { title: 'HubSpot ID', render: (c: any) => <div>{c.hubspot_properties.id}</div> },
].map((e: any) => ({ ...e, ...allColsSettings }));

function Companies() {
  const { query, handleSearch, handleFilter } = useQuery();
  const { createRecord, pagination, handleChange, isLoading, recordList } = usePaginatedRecordList<any>({ query, recordPath: 'companies', initialAutoFetch: true});
  const elementRef: any = useRef(null);

  return (
    <Container>
      <PageHeader
        title="Companies"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <FormDrawer
            recordName="company"
            handleRecordAction={createRecord}
            requieredTextFields={['name', 'email', 'phone', 'city', 'state']}
          />
        ]}
        className="mb-md"
      />
      <Table
        columns={columns}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="small"
      />
    </Container>
  )
}

export default Companies;
