import { useEffect, useRef, useState } from "react";
import { notification, Modal } from 'antd';
import { dataApi } from "../api/dataApi";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { ancanaApi } from "../api/ancanaApi";
import { ApiServiceType, RecordFieldSchema } from "../types/core";
import { IContentType, ILocalizedText } from "../types/models";


export type QuerySetterParams = {
  query: URLSearchParams,
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<any>,
}
export type CustomQuerySetter = ((params: QuerySetterParams) => void) | null;


type RecordListCallback<RecordType> = (recordList: RecordType[]) => void;

const apiErrorResToString = (resObj: { [fieldName: string]: string[] } | string) => {
  // When the API is in debug mode it sends html that neatly shows the stack trace of the error
  if (typeof resObj === 'string' && resObj.includes('html')) {
    const debugFrame = <iframe style={{ width: '100%', minHeight: '75vh' }} srcDoc={resObj} />;
    const modalProps = { width: 1100, content: debugFrame, closable: true, centered: true, icon: null };
    return (<a onClick={() => { Modal.info(modalProps); notification.destroy() }}>View debug info</a>);
  } else {
    let errorString;
    try {  // Attempt to "pretty print" field validation errors
      errorString = Object.entries(resObj).map((kv) => `${kv[0]} → ${kv[1][0]}`).join("\n");
    } catch {
      errorString = "";
    }
    return errorString;
  }
}

interface PropType<RecordType> {
  recordPath: string;
  query?: URLSearchParams;
  customQuerySetter?: CustomQuerySetter;
  pageSize?: string;
  initialAutoFetch?: boolean;
  onInitialAutoFetch?: RecordListCallback<RecordType>;
  extraQueryAppend?: string;
  apiService?: ApiServiceType;
  initialOptionsFetch?: boolean;
  optionsRecordId?: number | string;
  optionsPath?: string;
  reloadOnEdit?: boolean;
}
function usePaginatedRecordList<RecordType>(props: PropType<RecordType>) {
  const {
    query = null,
    recordPath,
    customQuerySetter = null,
    pageSize = '25',
    initialAutoFetch = false,
    apiService = 'data',
    extraQueryAppend = '',
    initialOptionsFetch = false,
  } = props;
  const apiInstance = apiService === 'data' ? dataApi : ancanaApi;
  const [recordList, setRecordList] = useState<RecordType[]>([]);
  const initialPagination = {
    current: parseInt((query ? query.get('page') : '1') || '1'),
    pageSize: parseInt((query ? query.get('page_size') : pageSize) || pageSize)
  };
  const [pagination, setPagination] = useState<any>(initialPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordFields, setRecordFields] = useState<{[field: string]: RecordFieldSchema}>();
  const [contentType, setContentType] = useState<IContentType>();
  const reqRef = useRef('init');

  useEffect(() => {
    if (initialAutoFetch) {
      firstFetch(props.onInitialAutoFetch);
    }
    if (initialOptionsFetch) {
      fetchOptions(props.optionsRecordId, props.optionsPath);
    }
  }, []);

  const fetchOptions = (recordId?: number | string, action?: string) => {
    const extraPath = (recordId === undefined ? '' : `${recordId.toString()}/`) + (action === undefined ? '' : action);
    apiInstance.options(`${recordPath}/${extraPath}`).then(res => {
      setRecordFields(res.data?.actions?.POST);
      setContentType(res.data?.content_type);
    });
  }

  const fetchRecordLocalizedTexts = async (id: number): Promise<ILocalizedText[]> => {
    const res = await apiInstance.get(`localized-texts/?content_type=${contentType?.id}&object_id=${id}&page_size=50`);
    return res.data.results;
  }

  const fetch = ({ pagination, filters = null, callback = (records: RecordType) => {}, queryAppend = '', queryReplace = undefined }: any) => {
    const queryStr = queryReplace || extraQueryAppend+queryAppend;
    const ranId = (Math.random() + 1).toString(36).substring(2);
    reqRef.current = ranId;
    setIsLoading(true);
    apiInstance.get(`${recordPath}/?${query ? query.toString() : `page_size=${pageSize}`}${queryStr}`).then((res: any) => {
      if (res.status === 200 && ranId === reqRef.current) {
        setRecordList(res.data.results);
        setPagination({
          ...pagination,
          total: res.data.count,
        })
        if (callback) {
          callback(res.data.results);
        }
      }
      setIsLoading(false);
    }).catch(e => {
      notification.error({ message: e.message, description: apiErrorResToString(e.response?.data), placement: 'bottomRight', duration: 10 });
      setIsLoading(false);
    });
  };

  const firstFetch = (callback?: (records: RecordType[]) => void, queryAppend?: string, queryReplace?: string, pageSize = '25') => {
    query?.set('page', pagination.current ? pagination.current.toString() : '1');
    query?.set('page_size', pagination.pageSize ? pagination.pageSize.toString() : pageSize);

    fetch({
      pagination,
      callback,
      queryAppend,
      queryReplace
    });
  }

  const fetchRecord = (recordId: any) => {
    return apiInstance.get(`${recordPath}/${recordId}/`);
  }

  const createRecord = (data: RecordType, afterCreate?: (res: any) => void, config: any = undefined) => {
    apiInstance.post(`${recordPath}/`, data, config).then((res: any) => {
      fetch({ pagination });
      if (afterCreate) afterCreate(res);
    }).catch((e) => {
      notification.error({ message: e.message, description: apiErrorResToString(e.response?.data), placement: 'bottomRight', duration: 10 });
    });
  }

  const deleteRecord = (id: any) => {
    apiInstance.delete(`${recordPath}/${id}/`).then((res: any) => {
      fetch({ pagination });
      notification.success({ message: 'record deleted succesfully', placement: 'bottomRight' });
    }).catch((e) => {
      notification.error({ message: e.message, description: apiErrorResToString(e.response?.data), placement: 'bottomRight', duration: 10 });
    })
  }

  const patchRecord = (id: number | string, partialData: any, pkName = 'id', config: any = undefined) => {
    apiInstance.patch(
      `${recordPath}/${id}/?${query ? query.toString() : `page_size=${pageSize}`}${extraQueryAppend}`,
      partialData,
      config
    ).then((res: any) => {
      notification.success({ message: 'record updated', placement: 'bottomRight' });
      // setRecordList(recordList.map((record: any) => record[pkName] === id ? {...record, ...partialData} : record));
      if (props.reloadOnEdit) {
        fetch({ pagination });
      } else {
        setRecordList(recordList.map((record: any) => record[pkName] === id ? res.data : record));
      }
    }).catch((e) => {
      notification.error({ message: e.message, description: apiErrorResToString(e.response?.data), placement: 'bottomRight', duration: 10 });
    });
  }

  const doRecordAction = (actionName: string, method: 'get' | 'post' | 'patch' = 'get', data = {}, detailId: any = null) => {
    const actionUrl = detailId ? `${recordPath}/${detailId}/${actionName}/` : `${recordPath}/${actionName}/`
    setIsLoading(true);
    apiInstance[method](actionUrl, data).then((res: any) => {
      fetch({ pagination });
      notification.success({ message: `${actionName} performed`, placement: 'bottomRight' });
    }).catch((e) => {
      notification.error({ message: e.toString(), placement: 'bottomRight', duration: 10 });
      setIsLoading(false);
    });
  }

  // deprecated in favor of doRecordAction with param detailId
  const doRecordDetailAction = (id: any, actionName: string, method: 'get' | 'post' | 'patch' = 'get', data = {}) => {
    apiInstance[method](`${recordPath}/${id}/${actionName}/`, data).then((res: any) => {
      fetch({ pagination });
    });
  }

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: any
  ) => {
    query?.set('page', pagination.current ? pagination.current.toString() : '1');
    query?.set('page_size', pagination.pageSize ? pagination.pageSize.toString() : '25');

    if (customQuerySetter && query) {
      customQuerySetter({ query, pagination, filters, sorter });
    } else {
      if (!!sorter.order) {
        query?.set('ordering', `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`);
      } else {
        query?.delete('ordering');
      }
    }
    // window.location.search = query.toString();

    fetch({
      pagination,
      filters
    });
  };

  const fetchNextPage = (setLoading = false) => {
    if (isLoading) {
      return;
    }
    if (setLoading) setIsLoading(true);
    apiInstance.get(`${recordPath}/?page_size=8&page=${pagination.current}&${query?.toString()}${extraQueryAppend}`)
      .then((res: any) => {
        setRecordList([...recordList, ...res.data.results]);
        setPagination({ ...pagination, current: (parseInt(pagination.current) + 1).toString(), total: res.data.count });
        setIsLoading(false);
      }).catch(() => {
      setIsLoading(false);
    });
  };

  return {
    fetch,
    firstFetch,
    fetchNextPage,
    handleChange,
    isLoading,
    recordList,
    setRecordList,
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    doRecordDetailAction,
    doRecordAction,
    fetchOptions,
    fetchRecord,
    recordFields,
    contentType,
    fetchRecordLocalizedTexts
  }
}

export default usePaginatedRecordList;
