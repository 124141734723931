import { ReactNode } from "react";
import styled from "styled-components";
import { Layout } from "antd";
const { Sider } = Layout;

interface HubSidedContentProps {
  leftContent: ReactNode;
  children?: ReactNode;
  rightContent: ReactNode;
}

const Container = styled.div`
  aside {
    overflow-y: scroll;
    background-color: white;
    min-width: 25% !important;
    max-width: 25% !important;
  }
  section {
    height: 100%;
  }

  .ant-card {
    margin-bottom: 8px;
  }
  .ant-card-body {
    padding: 8px 12px;
  }

  .center-cont {
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .ant-card-body {
      padding: 0;
    }
    .ant-card-head {
      padding: 0 16px;
    }
    .ant-card-head-title {
      font-size: 14px;
    }
    .ant-card-extra {
      font-size: 12px;
    }
  }
  .ant-descriptions-item-label {
    padding: 14px 16px;
  }
  .ant-descriptions-item-content {
    padding: 14px 16px;
  }
`;

function HubSidedContent(props: HubSidedContentProps) {
  return (
    <Container className="anti-contento">
      <Layout>
        <Sider>
          {props.leftContent}
        </Sider>

        <div className="center-cont">
          {props.children}
        </div>

        <Sider>
          {props.rightContent}
        </Sider>
      </Layout>
    </Container>
  );
}

export default HubSidedContent;
