import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Table } from 'antd';
import dayjs from "dayjs";

import { dataApi } from "../api/dataApi";
import CodeView from "../components/CodeView";

const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const columns = [
  { title: 'Type', dataIndex: 'is_pull', sorter: true, key: 'is_pull', render: (is_pull: boolean) => <>{is_pull ? 'Pull' : 'Push'}</> },
  {
    title: 'Status',
    dataIndex: 'is_successful',
    // sorter: true,
    key: 'is_successful',
    render: (is_successful: boolean) => (
      <Badge status={is_successful ? 'success' : 'error'} text={is_successful ? 'Success' : 'Error'} />
    ),
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    // sorter: true,
    key: 'timestamp',
    render: (text: string) => <>{day(text).calendar(null, { sameElse: 'MMM D, YYYY' })}</>,
  },
];

function SyncResults(props: any) {
  const { source_id } = useParams();
  const [syncResults, setSyncResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const elementRef: any = useRef(null);

  useEffect(() => {
    setLoading(true);
    dataApi.get(`syncresults/?source=${source_id}`).then((res: any) => {
      if (res.status === 200) {
        setSyncResults(res.data.results.map((res: any) => ({ ...res, key: res.id })));
      }
      setLoading(false);
    });
  }, []);

  return (
    <div style={{ height: '100%' }} ref={elementRef}>
      <Table
        dataSource={syncResults}
        columns={columns}
        expandable={{
          expandedRowRender: record => (
            <div>
              {record.data.length?.toLocaleString('en-US')} Records
              <CodeView jsonString={record.data} />
            </div>
          ),
          rowExpandable: record => Object.keys(record.data).length > 0,
        }}
        loading={loading}
        scroll={{ y: elementRef.current?.clientHeight - 100 }}
      />
    </div>
  )
}

export default SyncResults;
