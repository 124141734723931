import styled from 'styled-components';

const Container = styled.div`
`;

function PremiumDates() {
  return (
    <Container>
      PremiumDates
    </Container>
  )
}

export default PremiumDates;
