import styled from 'styled-components';
import { ReactNode, useEffect, useMemo, useState } from "react";
import moment, { Moment } from "moment/moment";
import { Button, Dropdown, Menu, Select } from "antd";
import { CommentOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IComment } from "../types/models";
import { ancanaApi } from "../api/ancanaApi";
import DateComment from "../components/DateComment";
import * as React from "react";


const CalHeader = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  width: 100%;

  .date {
    font-size: 18px;
    font-weight: 500;
  }
`;

const DateCell = styled.div<any>`
  height: 100%;
  background-color: ${({ isSelected }) => isSelected ? '#f3d5ba' : 'white'};
  padding: 8px 12px;
  outline: 1px solid #f0f0f0;
  user-select: none;

  .day-label {
    font-size: 12px;
    font-weight: 300;
  }
`;

interface PropsType {
  selectable?: boolean;
  filters?: ReactNode;
  dateCellContentRender?: (date: Moment) => ReactNode;
  onlyMonthDays?: boolean;
  cellContextMenu?: React.ReactElement | (() => React.ReactElement);
  baseCommentsUrl?: string;
}

function useCustomCalendar(props?: PropsType) {
  const [currentMonthDate, setCurrentMonthDate] = useState<any>(moment());
  const {
    selectable = false,
    dateCellContentRender = (date: Moment) => <></>,
    onlyMonthDays = false
  } = props || {};
  const [dateStart, setDateStart] = useState<Moment>();
  const [dateEnd, setDateEnd] = useState<Moment>();
  const selectedDateRange: Moment[] = useMemo(() => {
    if (!dateStart) return [];
    if (!dateEnd) return [dateStart];
    const datesDiff = dateStart.diff(dateEnd);
    if (datesDiff === 0) return [dateStart];
    if (datesDiff < 0) return [dateStart, dateEnd];
    if (datesDiff > 0) return [dateEnd, dateStart];
    return []
  }, [dateStart, dateEnd]);
  const [comments, setComments] = useState<IComment[]>([]);
  const [currentCellContextMenu, setCurrentCellContextMenu] = useState<string>();

  const addEmptyComment = (date: string) => {
    const emptyComment: IComment = {
      id: 0, author: 0, date,
      author_name: 'Me',
      content: '',
      content_type: 205,
      object_id: 1,
      created_at: '',
      updated_at: '',
    };
    setComments([...comments, emptyComment]);
    setCurrentCellContextMenu(undefined);
  }

  const removeEmptyComment = () => {
    setComments(comments.filter(comment => comment.id !== 0));
  }

  const createComment = (content: string) => {
    const data = { content };
    if (!!props?.baseCommentsUrl) {
      ancanaApi.post(`${props.baseCommentsUrl}/comments/`, data).then(res => {
        setComments([ ...comments, res.data ]);
      });
    }
  }

  const deleteComment = (id: number) => {
    ancanaApi.delete(`comments/${id}/`).then(res =>
      setComments(comments.filter(c => c.id !== id))
    );
  }

  const editComment = (id: number, data: any) => {
    ancanaApi.patch(`comments/${id}/`, data).then(res => {
      setComments([ ...comments.filter(c => c.id !== id), res.data ]);
    });
  }

  const headerRender = (object: { value: Moment, type: string, onChange: any, onTypeChange: any }) => {
    const { value, type, onChange } = object;
    const year = value.year();
    const options = [];
    for (let i = year - 3; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }

    return (
      <CalHeader>
        <div className="full-width center justify-between pb-md">
          <div className="flx1">
            {props?.filters}
          </div>
          <div className="date h-center flx1">
            {value.format("MMMM YYYY")}
          </div>
          <div className="flx1" style={{ justifyContent: 'flex-end' }}>
            <Select
              dropdownMatchSelectWidth={false}
              className="mr-md"
              value={year}
              onChange={(newYear: number) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
              style={{ width: 100 }}
            >
              {options}
            </Select>

            <Button onClick={() => onChange(value.clone().startOf('month').subtract(1, 'days'))}>
              <LeftOutlined />
            </Button>
            <Button onClick={() => onChange(value.clone().endOf('month').add(1, 'days'))}>
              <RightOutlined />
            </Button>
          </div>
        </div>
      </CalHeader>
    )
  }

  const dateCellFullRender = (value: Moment) => {
    const stringValue = value.format('YYYY-MM-DD');
    const checkIsSelected = () => {
      switch (selectedDateRange.length) {
        case 0:
          return false;
        case 1:
          return value.isSame(selectedDateRange[0]);
        case 2:
          return value.isBetween(selectedDateRange[0], selectedDateRange[1], undefined,  "[]");
        default:
          return false;
      }
    }

    if (onlyMonthDays && value.month() !== currentMonthDate.month()) return (
      <div style={{ width: '100%', height: '100%', background: '#fafafa', outline: '1px solid #f0f0f0' }} />
    );

    const comment = comments.find(c => c.date === stringValue);

    return (
      <DateCell
        isSelected={checkIsSelected()}
        onSelect={() => console.log(value)}
        onMouseOver={(e: any) => {
          if (selectable && e.buttons === 1 && !!dateStart ) {
            setDateEnd(value);
          }
        }}
        onMouseDown={(e: any) => {
          if (selectable && e.buttons === 1) {
            setDateStart(value);
            setDateEnd(undefined);
          }
        }}
        onContextMenu={(e: any) => {
          e.preventDefault();
          setCurrentCellContextMenu(stringValue);
        }}
      >
        {comment && (
          <DateComment
            comment={comment}
            removeEmptyComment={removeEmptyComment}
            createComment={createComment}
            deleteComment={deleteComment}
            editComment={editComment}
          />
        )}

        <Dropdown
          trigger={["click"]}
          visible={currentCellContextMenu === stringValue}
          onVisibleChange={(visible) => !visible ? setCurrentCellContextMenu(undefined) : null}
          placement="top"
          overlay={(props && props.cellContextMenu) ? (
            props.cellContextMenu
          ) : (
            <Menu>
              <Menu.Item icon={<CommentOutlined />} onClick={() => addEmptyComment(stringValue)}>Add comment</Menu.Item>
            </Menu>
          )}
        >
          <div className="day-label">
            {value.date().toString()}
          </div>
        </Dropdown>

        {dateCellContentRender(value)}
      </DateCell>

    )
  }

  const onPanelChange = (m: any) => setCurrentMonthDate(m);

  useEffect(() => {
    if (currentMonthDate) {
      const end = currentMonthDate.endOf('month').format('YYYY-MM-DD');
      const start = currentMonthDate.clone().startOf('month').add(-7, 'days').format('YYYY-MM-DD');
      ancanaApi.get(`comments/?date_after=${start}&date_before=${end}`).then(res => {
        setComments(res.data.results);
      });
    }
  }, [currentMonthDate]);

  return {
    selectedDateRange,
    headerRender,
    dateCellFullRender,
    setDateStart,
    setDateEnd,
    onPanelChange,
    currentMonthDate
  }
}

export default useCustomCalendar;
