import { RecordFieldSchema, RecordModel } from "../../types/core";
import { ReactNode, useEffect, useState } from "react";
import { ApiTwoTone } from '@ant-design/icons'
import { ancanaApi } from "../../api/ancanaApi";
import { Tooltip } from "antd";

interface RelatedFieldCellProps<T> {
  fieldName: string,
  fieldDefinition: RecordFieldSchema,
  record: T,
  recordModel: RecordModel<T>,
}

function RelatedFieldCell<T>(props: RelatedFieldCellProps<T>) {
  const [cellComponent, setCellComponent] = useState<ReactNode>();
  const { fieldName, record, recordModel } = props;

  const importModel = async () => {
    let modelName: any = !!recordModel.fieldModels ? recordModel.fieldModels[props.fieldName] : undefined;
    modelName = modelName || (
      fieldName
        .replace(/_([a-z])/g, (_, group1) => group1.toUpperCase())
        .replace(/^\w/, firstChar => firstChar.toUpperCase())
    )
    const r: any = await import('../../types/models/index');
    let model: RecordModel<any> = r[`${modelName}Model`];
    if (model === undefined && modelName.slice(-1) === 's') {   // Plural case
      model = r[`${modelName.slice(0,-1)}Model`];
    }
    return model;
  }

  const fetchFieldData = async (recordModel: RecordModel<any>, id: any) => {
    const res = await ancanaApi.get(`${recordModel.path}/${id}/?awd=1${recordModel.tableQuery}`);
    return res.data;
  }

  const extraCallWarn = (
    <Tooltip title="Extra API call for this cell">
      <ApiTwoTone style={{ fontSize: 12 }} />
    </Tooltip>
  )

  useEffect(() => {
    importModel().then(relatedModel => {
      const fieldValue: any = (record as any)[fieldName];
      if (typeof fieldValue === 'number') {
        fetchFieldData(relatedModel, fieldValue).then(data => {
          setCellComponent(<>{extraCallWarn} {relatedModel.toString(data)}</>);
        });
      } else {
        let recordString = relatedModel.toString(fieldValue);
        if (recordString.includes('undefined')) {
          fetchFieldData(relatedModel, fieldValue.id).then(data => {
            setCellComponent(<>{extraCallWarn} {relatedModel.toString(data)}</>);
          });
        } else {
          setCellComponent(recordString);
        }
      }
    });
  }, []);

  return (<>{cellComponent}</>);
}

export default RelatedFieldCell;
