import React, { CSSProperties, memo, useState } from 'react';
import { arrayMove, SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
// import './pictureGrid.css';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import UploadList from 'antd/es/upload/UploadList';
import { Modal, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { ReactNode } from 'react';
import styled from "styled-components";

const Container = styled.div`
  .SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0 1px 6px, rgba(0, 0, 0, 0.075) 0 1px 4px;
    background-color: lightgreen;
  }
`;
export type Props = {
  onSort: (params: { fileList: UploadFile[] }) => void;
  children?: ReactNode;
} & UploadProps

type SortableParams = {
  props: Omit<Props, 'onChange'>;
  onPreview: (file: UploadFile) => void;
  onRemove: (file: UploadFile) => void | boolean;
}

export type SortableItemParams = {
  item: UploadFile;
} & SortableParams

export type SortableListParams = {
  onChange: (info: UploadChangeParam) => void;
  items: UploadFile[];
} & SortableParams


const getBase64 = (file: File | Blob | undefined): Promise<string> => {
  if (!file) return Promise.reject(new Error('no file'));
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file!);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const imagePreview = async (file: UploadFile, callback: (params: { image: string }) => void) => {
  const newFile = file;
  if (!newFile.url && !newFile.preview) {
    newFile.preview = await getBase64(file.originFileObj);
  }
  const newPreviewImage: string = newFile.url || newFile.preview || '';
  callback({
    image: newPreviewImage,
  });
};

const itemStyle: CSSProperties = {
  width: 104,
  height: 104,
  marginRight: 8,
  marginBottom: 8,
  cursor: 'grab'
};
const SortableItem: any = SortableElement((params: SortableItemParams) => (
  <div style={itemStyle}>
    <UploadList
      locale={{ previewFile: 'preview', removeFile: 'remove' }}
      showDownloadIcon={false}
      listType={params.props.listType}
      onPreview={params.onPreview}
      onRemove={params.onRemove}
      items={[params.item]}
    />
  </div>
));


const listStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '100%',
};
const SortableList: any = SortableContainer((params: any) => {
  return (
    <div style={listStyle}>
      {params.items.map((item: any, index: any) => (
        <SortableItem
          key={`${item.uid}`}
          index={index}
          item={item}
          props={params.props}
          onPreview={params.props.onPreview}
          onRemove={params.props.onRemove}
        />
      ))}
      <Upload
        {...params.props}
        showUploadList={false}
      >
        {params.props.children}
      </Upload>
    </div>
  );
});

const DraggableUpload: React.FC<Props> = memo(({ onSort, ...props }) => {
  const [previewImage, setPreviewImage] = useState('');
  const fileList = props.fileList || [];
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    onSort({ fileList: arrayMove(fileList, oldIndex, newIndex) });
  };

  return (
    <Container>
      <SortableList
        distance={1}
        items={fileList}
        onSortEnd={onSortEnd}
        axis="xy"
        helperClass="SortableHelper"
        props={props}
      />
      <Modal
        visible={!!previewImage}
        footer={null}
        onCancel={() => setPreviewImage('')}
        bodyStyle={{ padding: 0 }}
      >
        <img style={{ width: '100%' }} alt="" src={previewImage} />
      </Modal>
    </Container>
  );
});

export default DraggableUpload;
