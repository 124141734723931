import { useEffect, useState } from "react";
import { notification } from "antd";

import { ancanaApi } from "../api/ancanaApi";
import { useNavigate } from "react-router-dom";

interface User {
  id: number;
  email: string;
  token: string;
  first_name?: string;
  last_name?: string;
  role?: string;
}

function useAuth(setError?: any) {
  // const authFlow = useContext(AuthFlowContext);
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const _goToUserHome = () => {
    if (user?.role === 'owner') {
      navigate('/owner');
    } else {
      navigate('/');
    }
  }

  const goToUserHome = () => {
    if (user) {
      _goToUserHome();
    } else {

    }
  }

  const login = async (data: any) => {
    ancanaApi.post('users/login/', data).then((res) => {
      if (res.status === 200) {
        localStorage.setItem('user', JSON.stringify(res.data));
        setUser(res.data);
        window.location.reload();
      } else {
        notification.error({ message: `Error: ${res.status}` });
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        notification.error({ message: 'Invalid credentials' });
        if (!!setError) {
          setError('email', { type: 'manual', message: ' ' });
          setError('password', { type: 'manual', message: 'Invalid credentials' });
        }
      } else {
        notification.error({ message: err.message });
      }
    });
  }

  const logout = () => {
    localStorage.removeItem('user');
    setUser(undefined);
    // navigate('/');
    window.location.reload();
  }

  return {
    user,
    login,
    goToUserHome,
    logout,
  }
}

export default useAuth;
