import { RecordModel } from "../core";
import { components } from "../schema";
import RecordSelect from "../../components/fields/RecordSelect";
import { CurrencyModel } from "../coreModels";
import { UnitModel } from "./housing";
import { Form, Select } from "antd";

export type IOpex = components['schemas']['Opex'];
export type ICapex = components['schemas']['Capex'];
export type IUnitCapex = components['schemas']['UnitCapex'];
export type IUnitOpex = components['schemas']['UnitOpex'];


export const OpexModel: RecordModel<IOpex> = {
  name: "Operating Exp",
  path: "op-exps",
  selectQuery: "&fields=id,concept",
  tableQuery: "",
  toString: (r: IOpex): string => r.concept ? r.concept : r.toString(),
  customCreateFields: [
    {
      name: 'periodicity',
      component: (
        <Form.Item name="periodicty" label="Periodicity">
          <Select>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="yearly">Yearly</Select.Option>
          </Select>
        </Form.Item>
      )
    },
  ]
}

export const CapexModel: RecordModel<ICapex> = {
  name: "Capital Exp",
  path: "cap-exps",
  selectQuery: "&fields=id,concept",
  tableQuery: "",
  toString: (r: ICapex): string => r.concept ? r.concept : r.toString(),
  customTableMappings: {
    currency: { path : ['currency', 'iso']},
    item: { path : ['item', 'description']},
  },
  customCreateFields: [
    { name: 'currency', component: <RecordSelect recordModel={CurrencyModel} /> },
  ]
}

const MXN_CURRENCY_ID = "f36b4827-23a1-4a38-83c0-6640dd0c9f53";

export const UnitCapexModel: RecordModel<IUnitCapex> = {
  name: "Capital Expenditures",
  path: "unit-cap-exps",
  selectQuery: "",
  tableQuery: "&expand=unit,capex,currency",
  toString: (r: any): string => r.capex.concept ? r.capex.concept : r.toString(),
  customTableMappings: {
    currency: { path : ['currency', 'iso']},
    unit: { path : ['unit', 'name']},
    capex: { path : ['capex', 'concept']},
  },
  customCreateFields: [
    { name: 'unit', component: <RecordSelect recordModel={UnitModel} allowManage /> },
    { name: 'currency', component: <RecordSelect defaultValue={MXN_CURRENCY_ID} recordModel={CurrencyModel} allowManage /> },
    { name: 'capex', component: <RecordSelect formName="capex" recordModel={CapexModel} allowManage /> },
  ]
}

export const UnitOpexModel: RecordModel<IUnitOpex> = {
  name: "Operational Expenses",
  path: "unit-op-exps",
  selectQuery: "",
  tableQuery: "&expand=unit,opex,currency",
  toString: (r: any): string => r.opex.concept ? r.opex.concept : r.toString(),
  customTableMappings: {
    currency: { path : ['currency', 'iso']},
    unit: { path : ['unit', 'name']},
    opex: { path : ['opex', 'concept']},
  },
  customCreateFields: [
    { name: 'unit', component: <RecordSelect recordModel={UnitModel} allowManage /> },
    { name: 'currency', component: <RecordSelect defaultValue={MXN_CURRENCY_ID} recordModel={CurrencyModel} allowManage /> },
    { name: 'opex', component: <RecordSelect formName="opex" recordModel={OpexModel} allowManage /> },
  ]
}
