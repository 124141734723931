import { RecordModel } from "./core";
import { IUser } from "./models/auth";

export interface CurrencyType {
  id: string;
  name: string;
  symbol: string;
  iso: string;
}

export interface OwnerType {
  id: number;
  unit: {
    name: string;
    fractions: number;
  };
  owner: IUser;
  main_accounting_journal: number | null;
  color_code: string;
  fraction_numbers: number[];
  contribution_account?: {
    id: number;
    name: string;
    normal_balance: string;
  },
  cash_account?: {
    id: number;
    name: string;
    normal_balance: string;
  },
  expenses_account?: {
    id: number;
    name: string;
    normal_balance: string;
  }
}

export const CurrencyModel: RecordModel<CurrencyType> = {
  name: "Currency",
  path: "currencies",
  selectQuery: "",
  tableQuery: "",
  toString: (r: CurrencyType) => r.iso
}
