import styled from 'styled-components';
import { IComment } from "../types/models";
import moment from "moment/moment";
import { Dropdown, Button, Menu, Input } from "antd";
import { ancanaApi } from "../api/ancanaApi";
import { useEffect, useState } from "react";

const Container = styled.div`
  .author {
    color: #484a4a;
    font-size: 12px;
  }

  .timestamp {
    color: #9e9d9d;
    font-size: 11px;
  }

  .content {
    font-weight: 500;
  }
`;

interface CommentDetailProps {
  comment: IComment;
  createComment: any;
  deleteComment: any;
  editComment: any;
  creating?: boolean;
  onCancel?: any;
}

function CommentDetail(props: CommentDetailProps) {
  const { comment, createComment, deleteComment, editComment, creating = false } = props;
  const [value, setValue] = useState<string | null>(null);

  const handleSave = () => {
    if (creating) {
      createComment(value);
    } else {
      editComment(comment.id, { content: value });
    }
    setValue(null);
  }

  useEffect(() => {
    if (creating) {
      setValue(comment.content);
    }
  }, [creating]);

  return (
    <Container>
      <div className="disp-flex center h-stack-strict gap-sm mb-sm">
        <div className="flx1">
          <div className="author">
            {comment.author_name}
          </div>
          <div className="timestamp">
            {moment(comment.date).format('LL')}
          </div>
        </div>
        <Dropdown
          trigger={["click"]}
          overlay={(
            <Menu>
              <Menu.Item onClick={() => setValue(comment.content)}>Edit</Menu.Item>
              <Menu.Item onClick={() => deleteComment(comment.id)}>Delete</Menu.Item>
            </Menu>
          )}
        >
          <Button type="link" style={{ fontWeight: 900, fontSize: 20, marginTop: -16, marginRight: -4, padding: '0 8px' }}>⋮</Button>
        </Dropdown>
      </div>
      <div className="content">
        {value === null ? comment.content : (
          <>
            <Input.TextArea value={value} autoSize={{ minRows: 3, maxRows: 10 }} onChange={e => setValue(e.target.value)} className="mb-sm" />
            <Button size="small" className="mr-sm" onClick={() => { setValue(null); if(props.onCancel) props.onCancel() }}>
              Cancel
            </Button>
            <Button size="small" type="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        )}
      </div>
    </Container>
  )
}

export default CommentDetail;
