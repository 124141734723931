import styled from 'styled-components';
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import useQuery from "../../hooks/useQuery";
import { Button, PageHeader, Select, Table, Tag, Input, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import RecordDrawer from "../../components/RecordDrawer";
import RecordGalleriesModal from "../../components/RecordGalleriesModal";
import moment from "moment";
import { GroupModel, IUser, UserModel } from "../../types/models/auth";
import RecordTable from "../../components/RecordTable";

const Container = styled.div`
  height: 100%;

  .ant-tag {
    margin-right: 0;
  }
`;

const allColsSettings = { ellipsis: true }
const getColumns = (setRecordToEdit: any, deleteRecord: any) => ([
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: 50,
      fixed: 'left',
      // render: (text: string, c: any) => <Link to={`/crm/contacts/${c.id}`}>{c.name}</Link>
    },
    { title: 'First Name', dataIndex: 'first_name', sorter: true, key: 'first_name', width: 150 },
    { title: 'Last Name', dataIndex: 'last_name', sorter: true, key: 'last_name', width: 150 },
    { title: 'Email', dataIndex: 'email', sorter: true, key: 'email', width: 250 },
    {
      title: 'Type',
      dataIndex: 'is_staff',
      sorter: true,
      render: (staff: boolean) => staff ? 'Staff' : 'Owner',
      width: 90
    },
    {
      title: 'Permission Groups', dataIndex: 'last_name', sorter: true,
      render: (txt: any, obj: any) => (
        <div style={{ display: 'flex', flexWrap: "wrap", gap: 6 }}>
          {obj.groups.map((g: any) => (<Tag className=""><a href="/permissions">{g.name}</a></Tag>))}
          {obj.is_superuser && (<Tag color="blue">Super User</Tag>)}
        </div>
      )
    },
    {
      title: 'Date Joined', dataIndex: 'date_joined', sorter: true, width: 135,
      render: (txt: any, obj: any) => (<div>{(moment(obj.date_joined)).format('ll')}</div>)
    },
    {
      title: 'Actions', render: (r: any) => (
        <div className="row-actions">
          <a onClick={() => setRecordToEdit({ ...r, groups: r.groups.map((g: any) => g.id) })}>Edit</a>
          <Popconfirm
            title={`Permanently delete this record?`}
            onConfirm={() => deleteRecord(r.id)}
            okText="Yes"
          >
            <a>Delete</a>
          </Popconfirm>
        </div>
      ),
      width: 110,
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }))
);

function Index() {
  const { query, handleSearch, handleFilter } = useQuery();
  const { recordList, firstFetch, pagination, patchRecord, createRecord, isLoading, handleChange } = usePaginatedRecordList<any>({
    query,
    recordPath: 'users',
    extraQueryAppend: '&expand=groups',
    apiService: 'admin'
  });
  const elementRef = useRef<any>();
  const [recordToEdit, setRecordToEdit] = useState<IUser | null>(null);

  useEffect(() => {
    firstFetch();
  }, []);

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Users"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <RecordTable modalView recordModel={GroupModel} />,
          <RecordDrawer saveAction={createRecord} recordModel={UserModel} />
        ]}
        className="mb-md"
      >
        <Input.Search
          placeholder="Search by name or email..."
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <Select
          showSearch
          allowClear
          loading={isLoading}
          placeholder="Filter by type"
          optionFilterProp="children"
          defaultValue={query.get('is_staff')}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={[{ value: 'true', label: 'Staff' }, { value: 'false', label: 'Owner' }]}
          onChange={(value) => handleFilter('is_staff', value)}
          style={{ minWidth: 150 }}
        />
      </PageHeader>

      <Table
        columns={getColumns(setRecordToEdit, () => {})}
        dataSource={recordList}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 175 }}
        size="middle"
      />

      <RecordDrawer<IUser>
        recordModel={UserModel}
        // customCreateFields={createRecord}
        saveAction={patchRecord}
        // customRecordName={props.customRecordName}
        recordInstance={recordToEdit}
        onClose={() => setRecordToEdit(null)}
      />
    </Container>
  )
}

export default Index;
