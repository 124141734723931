import styled from 'styled-components';
import { Tag, Descriptions, PageHeader } from 'antd';
import { useEffect, useState } from "react";
import { ancanaApi } from "../../api/ancanaApi";
import { useParams } from "react-router-dom";


const Container = styled.div`
`;

function OwnerDetail() {
  const { owner_id } = useParams();
  const [houseOwner, setHouseOwner] = useState<any>();
  const [preferredDates, setPreferredDates] = useState<any[]>([]);

  useEffect(() => {
    ancanaApi.get(`unit-owners/${owner_id}/?expand=owner,unit`).then(res => {
      setHouseOwner(res.data);
    })
  }, []);

  useEffect(() => {
    if (houseOwner) {
      ancanaApi.get(`house-owner-preferred-date-range/?house_owner=${houseOwner.id}&page_size=25`).then(res => {
        setPreferredDates(res.data.results);
      })
    }
  }, [houseOwner]);

  return (
    <Container>
      <PageHeader
        onBack={() => window.history.back()}
        title={houseOwner?.owner.full_name}
        subTitle={houseOwner?.owner.email}
        className="mb-md"
      />
      <Descriptions
        layout="horizontal"
        column={1}
        bordered
        style={{ maxWidth: 600 }}
      >
        <Descriptions.Item label="First Name" labelStyle={{ fontWeight: 500 }}>
          {houseOwner?.owner.first_name}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" labelStyle={{ fontWeight: 500 }}>
          {houseOwner?.owner.last_name}
        </Descriptions.Item>
        <Descriptions.Item label="House" labelStyle={{ fontWeight: 500, width: 200 }}>
          {houseOwner?.unit.name}
        </Descriptions.Item>
        <Descriptions.Item label="Fractions" labelStyle={{ fontWeight: 500 }}>
          {houseOwner?.fraction_numbers.map((fn: number) => <Tag>#{fn}</Tag>)}
        </Descriptions.Item>
        <Descriptions.Item label="Preferred Weeks" labelStyle={{ fontWeight: 500 }}>
          {preferredDates.map(pd => <Tag><div>{pd.date_start}</div><div>{pd.date_end}</div></Tag>)}
        </Descriptions.Item>
      </Descriptions>
    </Container>
  );
}

export default OwnerDetail;
