import { Identifiable, RecordModel } from "../core";

export interface IPrompt extends Identifiable {
  type: string;
  role: 'system' | 'user' | 'assistant';
  content: string;
  lang?: string | null;
}

export const PromptModel: RecordModel<IPrompt> = {
  name: "Prompt",
  path: "prompts",
  selectQuery: "",
  tableQuery: "",
  toString: r => `${r.type}-${r.role}: ${r.content.slice(0,20)}...`,
  customTableMappings: {
    content: { width: '40%' }
  },
  drawerWidth: 500,
}

export interface IChat extends Identifiable {
  id: string;
  name: string;
  content_type: number;
  object_id: number;
  field: string;
  current_completion_tokens: number;
  current_prompt_tokens: number;
  current_total_tokens: number;
}

export const CompletionChatModel: RecordModel<IChat> = {
  name: "Completion Chat",
  path: "field-completions",
  selectQuery: "",
  tableQuery: "",
  toString: r => !!r.name ? r.name : r.id,
  customTableMappings: {
    id: { omit: true },
    name: { render: r => CompletionChatModel.toString(r), width: 350 },
  }
}
