import styled from 'styled-components';
import { PageHeader, Table, Badge, Tag, Form, Input, Select, Popconfirm, Button, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined, WarningTwoTone } from "@ant-design/icons";
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import React, { useEffect, useRef, useState } from "react";
import { SyncOutlined, LinkOutlined } from "@ant-design/icons";
import { range } from "ramda";
import FormDrawer from "../../components/FormDrawer";
import RecordSelect from "../../components/fields/RecordSelect";
import { UnitModel, IUnit } from "../../types/models/housing";
import moment from "moment/moment";
import { ancanaApi } from "../../api/ancanaApi";
import { IUser, UserModel } from "../../types/models/auth";

const Container = styled.div`
  height: 100%;

  .ant-table-cell-ellipsis {
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
    }
  }
`;


const getColumns = (deleteRecord: any, patchRecord: any) => {
  const allColsSettings = { ellipsis: true };
  return [
    // { title: 'ID', dataIndex: 'id', sorter: true, key: 'id', width: 50 },
    { title: 'Owner', key: 'owner', sorter: true, render: (ho: any) => (
        <a className="h-stack-strict" href={`owners/${ho.id}`}>
          <div>{ho.owner.full_name}</div>
          <small>{ho.owner.email}</small>
        </a>
      ) },
    { title: 'Unit', dataIndex: ['unit'], render: (unit: any) => (
      <>{unit.name}</>
      ), sorter: true, key: 'house' },
    { title: 'Fractions', dataIndex: 'fraction_numbers', render: (frs: any) => <>{frs.map((f: number) => <Tag>#{f}</Tag>)}</>, key: 'fraction_numbers', width: 130 },
    { title: 'Created at', dataIndex: 'created_at', sorter: true, render: (c: string) => moment(c).format('ll'), width: 125 },
    {
      title: 'Stripe ID', dataIndex: ['owner', 'stripe_accounts'],
      render: (accounts: any[]) => <div>
        <small>{accounts[0]?.stripe_id && accounts[0]?.livemode ? 'Live Mode' : 'Test Mode'}</small>
        <div>{accounts[0]?.stripe_id}</div>
      </div>, key: 'stripe',  width: 180
    },
    { title: 'Spei', dataIndex: ['owner', 'stripe_accounts'],
      render: (accounts: any[]) => <div className="h-stack-strict">
        <small>{accounts[0]?.speiaccount.bank_name}</small>
        <p>{accounts[0]?.speiaccount.clabe}</p></div>, key: 'spei', width: 190 },
    {
      title: 'Cash Balance',
      dataIndex: ['owner', 'stripe_accounts', 0],
      render: (acc: any) => (
        <div className="h-stack-strict" style={{display: 'flex', flexWrap: 'wrap', gap: 0, alignItems: 'center' }}>
          <p>${(acc.mx_cash_balance / 100).toLocaleString()} MXN</p>
          {acc.cb_reconciliation_mode !== 'automatic' && (
            <Tooltip title={`${acc.cb_reconciliation_mode} reconciliation`}>
              <WarningTwoTone style={{ fontSize: 16, marginLeft: 6 }} twoToneColor="darkorange" />
            </Tooltip>
          )}
        </div>
      ),
      key: 'mx_cash_balance',
      width: 125
    },
    {
      title: 'Expenses', dataIndex: ['main_accounting_journal'],
      render: (journal: any | null) => <><Badge status={journal ? 'success':'error'} text={journal ? 'ready' : 'pending setup'} /></>, key: 'journal', width: 100 },
    {
      title: 'Actions', render: (r: any) => (
        <div className="row-actions">
          {r.opv &&
          <a href={`https://ancana.co/owners?opv=${r.opv}`} target="_blank">
            <LinkOutlined /> Portal
          </a>
          }
          <Popconfirm
            title={`Permanently delete this owner?`}
            onConfirm={() =>deleteRecord(r.id)}
            okText="Yes"
          >
            <a><DeleteOutlined /> Delete</a>
          </Popconfirm>
        </div>
      ),
      width: 155
    }
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

function Owners() {
  const { query, handleSearch } = useQuery();
  const {
    pagination,
    createRecord,
    deleteRecord,
    patchRecord,
    handleChange,
    isLoading,
    doRecordAction,
    recordList: owners
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'unit-owners',
    extraQueryAppend: '&expand=owner.stripe_accounts.speiaccount,unit,opv&omit=owner.groups',
    apiService: 'admin',
    pageSize: '25',
    initialAutoFetch: true
  });
  const elementRef: any = useRef(null);
  const [selectedHouseId, setSelectedHouseId] = useState();
  const [selectedHouseFractions, setSelectedHouseFractions] = useState<{ soldFractions: number[], totalFractions: number } | undefined>();

  useEffect(() => {
    if (selectedHouseId) {
      ancanaApi.get(`housing-units/${selectedHouseId}?expand=unit_ownerships`).then(res => {
        const totalFractions = res.data.fractions;
        const soldFractions = res.data.unit_ownerships.reduce((acc: any, curr:any) => acc+curr.fraction_numbers, []);
        setSelectedHouseFractions({ totalFractions, soldFractions });
      })
    }
  }, [selectedHouseId]);

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Owners"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <Button onClick={() => doRecordAction('refresh-cash-balances')}><SyncOutlined /> Cash Balances</Button>,
          <FormDrawer
            recordName="Owner"
            handleRecordAction={createRecord}
            handleValuesChange={(changedValues: any, allValues: any) => {
              setSelectedHouseId(allValues['unit'] || undefined);
            }}
            btnIcon={<PlusOutlined />}
          >
            <RecordSelect<IUser>
              recordModel={UserModel}
              formName="owner"
              allowManage
            />
            <RecordSelect<IUnit>
              recordModel={UnitModel}
              extraFilterQuery="&published=true"
              allowManage
            />
            <Form.Item
              name="fraction_numbers"
              label="Fractions"
              rules={[{ required: true }]}
              // initialValue={1}
            >
              <Select mode="multiple" disabled={!selectedHouseFractions}>
                {!!selectedHouseFractions && (
                  range(1,selectedHouseFractions.totalFractions+1).map(fn => (
                    <Select.Option
                      disabled={selectedHouseFractions.soldFractions.includes(fn)}
                      value={fn}
                    >
                      #{fn} {selectedHouseFractions.soldFractions.includes(fn) ? '(sold)' : ''}
                    </Select.Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </FormDrawer>
        ]}
        className="mb-md"
      >
        <Input.Search
          placeholder="Search by house, owner"
          onSearch={handleSearch}
          defaultValue={query.get('search') || ''}
          allowClear
          style={{ width: 300 }}
        />
        <RecordSelect<IUnit>
          recordModel={UnitModel}
          extraFilterQuery="&has_no_owner=false"
          filter
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, patchRecord)}
        dataSource={owners}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 180 }}
        size="small"
      />
    </Container>
  )
}

export default Owners;
