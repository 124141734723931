import { useEffect, useState } from "react";
import usePaginatedRecordList from "../hooks/usePaginatedRecordList";
import { ancanaApi } from "../api/ancanaApi";


interface GenericRelationLabelProps {
  content_type: number;
  object_id: number | string;
}

function GenericRelationLabel({ content_type, object_id }: GenericRelationLabelProps) {
  const { recordList: contentTypeList } = usePaginatedRecordList<any>({
    recordPath: "content-types",
    extraQueryAppend: "&models=unit,design,unitowner,user,location",
    apiService: 'admin', pageSize: '250',
    initialAutoFetch: true,
  });
  const [contentType, setContentType] = useState<any>();
  const [recordName, setRecordName] = useState<string>();

  useEffect(() => {
    if (content_type && object_id && contentTypeList) {
      const contentType = contentTypeList.find(ct => ct.id.toString() === content_type.toString());
      if (contentType) {
        setContentType(contentType);
        import("../types/models/generics").then(generics => {
          const { ContentTypeModels } = generics;
          const recordModel = ContentTypeModels[`${contentType.app_label}_${contentType.model}`];
          if (recordModel) {
            ancanaApi.get(`${recordModel.path}/${object_id}?${recordModel.selectQuery}`).then(res => {
              setRecordName( recordModel.toString(res.data));
            });
          }
        });
      }
    }
  }, [content_type, object_id, contentTypeList]);

  if (!contentType) return null;

  return (
    <div className="disp-flex pre-line">
      <div className="fs-1 fw-300 uppercase" style={{ fontStyle: 'italic' }}>{contentType?.app_label} {contentType?.model}</div>
      <div className="fs-1-5 fw-400">{recordName}</div>
    </div>
  )
}

// const GenericRelationLabel = ({ content_type, object_id }: any) => <div>awdawd</div>

export default GenericRelationLabel;
