import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { Moment } from "moment/moment";
import moment from "moment";
import { DatePicker, Form } from "antd";
import useQuery from "../../hooks/useQuery";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const Container = styled.div`
`;

interface YearSelectProps {
  formRef?: any;
  formItemStyle?: any;
  rules?: any;
  filter?: boolean;
  size?: SizeType;
}

function YearSelect(props: YearSelectProps) {
  const { query, handleFilter } = useQuery();
  const { filter } = props;
  const [currentMoment, setCurrentMoment] = useState<Moment>();
  const queryValue = query.get('year') ? moment().set('year', Number(query.get('year'))) : undefined;

  const handleChange = (date: any) => {
    if (filter) {
      handleFilter('year', date ? date.year().toString() : null);
    } else {
      props.formRef.current.setFieldsValue({ year: date.year() });
      setCurrentMoment(date);
    }
  }

  useEffect(() => {
    if (!filter) {
      setTimeout(() => {
        if (props.formRef.current.getFieldsValue().year) {
          setCurrentMoment(moment().set('year', props.formRef.current.getFieldsValue().year));
        }
      }, 250);
    }
  }, [props]);

  const select = (
    <DatePicker.YearPicker
      placeholder={filter ? 'Filter by year' : undefined}
      value={filter ? queryValue : currentMoment}
      onChange={handleChange}
      size={props.size}
    />
  )

  if (filter) return select;

  return (
    <div className="mb-ml">
      <Form.Item
        label="year"
        key="year"
        name="year"
        style={props.formItemStyle}
        rules={props.rules}
      >
        <input type="hidden" name="year" />
        {select}
      </Form.Item>
    </div>
  )
}

export default YearSelect;
