import styled from 'styled-components';
import { Button, Form, Input, Popconfirm } from "antd";
import React, { ReactNode, useRef, useState } from "react";
import { FormLayout } from "antd/lib/form/Form";

const Container = styled.div`
`;

interface PopformProps {
  children: ReactNode;
  formContent?: ReactNode;
  onSubmit: (data: any) => void;
  title?: string;
  layout?: FormLayout;
  placement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
}

function Popform(props: PopformProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <Container>
      <Popconfirm
        placement={props.placement || "top"}
        visible={open}
        title={() => (
          <div>
            <div className="mb-md">{props.title}</div>
            <Form
              form={form}
              name="basic"
              layout={props.layout}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={async (data) => {
                props.onSubmit(data);
                setConfirmLoading(true);
                  setOpen(false);
                  setConfirmLoading(false);
                  setOpen(false);
              }}
              onFinishFailed={() => {}}
              autoComplete="off"
            >
              {props.formContent}
            </Form>
          </div>
        )}
        onConfirm={() => form.submit()}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={() => setOpen(false)}
        okText="Save"
      >
        <div onClick={() => setOpen(true)}>
          {props.children}
        </div>
      </Popconfirm>
    </Container>
  )
}

export default Popform;
