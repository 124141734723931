import { RecordModel } from "../core";

export interface IAddress {
  id: string;
  location_name?: string;
  latitude: number;
  longitude: number;
}

export interface ILocation {
  id: number;
  name: string;
  slug: string;
}

export const LocationModel: RecordModel<ILocation> = {
  customTableMappings: undefined,
  name: "Location",
  path: "locations",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return r.name;
  },
  fieldModels: {
    type: 'LocationType'
  },
  hasImageGalleries: true,
};

export interface ILocationType {
  id: number;
  name: string;
  code: string;
}

export const LocationTypeModel: RecordModel<ILocationType> = {
  customTableMappings: undefined,
  name: "Location Type",
  path: "location-types",
  selectQuery: "",
  tableQuery: "",
  toString(r): string {
    return r.name;
  },
}
