import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { Calendar, Button, PageHeader } from "antd";
import { ancanaApi } from "../../api/ancanaApi";

import useCustomCalendar from "../../hooks/useCustomCalendar";
import moment from "moment/moment";
import RecordSelect from "../../components/fields/RecordSelect";
import { UnitModel } from "../../types/models/housing";
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";


const Container = styled.div`
  height: 100%;
`;

function Stays() {
  const { query } = useQuery();
  const {
    recordList: stayList,
    firstFetch,
  } = usePaginatedRecordList({
    recordPath: 'bookings',
    apiService: 'admin'
  });
  const { headerRender, dateCellFullRender, onPanelChange, currentMonthDate } = useCustomCalendar({
    onlyMonthDays: true,
    dateCellContentRender: () => <div>awd</div>,
    filters: [
      <RecordSelect
        recordModel={UnitModel}
        extraFilterQuery="&published=true&has_no_owner=false"
        autoSelectFirst
        filter
      />
    ]
  });
  const selectedUnit = query.get('unit');

  useEffect(() => {
    console.log('');
    firstFetch();
  }, [currentMonthDate]);

  return (
    <Container>
      <PageHeader
        title={`Stays`}
        subTitle={`${stayList.length.toLocaleString('en-US')}`}
        extra={[
          <Button type="primary" onClick={() => {}}>
            Create Stay
          </Button>
        ]}
        className="mb-md"
      />

      <Calendar
        mode="month"
        headerRender={headerRender}
        dateFullCellRender={dateCellFullRender}
        onPanelChange={onPanelChange}
      />
    </Container>
  )
}

export default Stays;
