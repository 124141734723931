import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ancanaApi } from "../../api/ancanaApi";

const Container = styled.div`
`;

function UnitDetail() {
  const { listing_id } = useParams();
  const [listing, setListing] = useState<any>();
  const [expenses, setExpenses] = useState<any[]>([]);

  useEffect(() => {
    ancanaApi.get(`houses/${listing_id}`).then(res => {
      setListing(res.data);
    });
  }, []);

  return (
    <Container>
      Unit Details
    </Container>
  )
}

export default UnitDetail;
