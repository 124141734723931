import { RecordModel } from "../core";

export interface IContact {
  id: string;
  name: string;
  email?: string;
  phone?: string;
}

export const ContactModel: RecordModel<IContact> = {
  name: "Contacts",
  path: "contacts",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r) => r.name,
  apiService: "data"
}

export interface IContactEvent {
  id: string;
  name: string;
  email?: string;
  phone?: string;
}

export const ContactEventModel: RecordModel<IContactEvent> = {
  name: "Contact Events",
  path: "contact-events",
  selectQuery: "&ordering=id",
  tableQuery: "",
  toString: (r) => r.name,
  apiService: "data"
}
