import styled from 'styled-components';
import React, { useRef } from "react";
import { PageHeader, Table, Form, DatePicker, Popconfirm, Badge } from "antd";
import useQuery from "../../hooks/useQuery";
import usePaginatedRecordList from "../../hooks/usePaginatedRecordList";
import FormDrawer from "../../components/FormDrawer";
import RecordSelect from "../../components/fields/RecordSelect";
import { CurrencyModel, CurrencyType, OwnerType } from "../../types/coreModels";
import moment from "moment";
import { UnitModel, OwnerModel } from "../../types/models/housing";
import TabularFileImportWizard from "../../components/TabularFileImportWizard";
import useRecordFiles from "../../components/useRecordFiles";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { PlusOutlined } from "@ant-design/icons";

const Container = styled.div`
  height: 100%;
`;

const invoiceBadgeStatuses: {[status: string]: PresetStatusColorType} = {
  draft: 'default',
  open: 'processing',
  paid: 'success',
  uncollectible: 'warning',
  void: 'warning',
  overdue: 'error',
}

const statusAdvanceAction: {[status: string]: string} = {
  draft: 'Send',
  open: '',
  paid: 'Duplicate',
  uncollectible: 'Duplicate',
  void: 'Duplicate',
  overdue: '',
}

const getColumns = (deleteRecord: any, doRecordDetailAction: any, expandRow: any) => {
  const allColsSettings = { ellipsis: true }
  return [
    // { title: 'ID', dataIndex: 'id', sorter: true, key: 'id', width: 60 },
    { title: 'House Owner', render: (inv: any) => <a href={`/invoices/${inv.id}`}>{inv.journal.owner_name}</a>, sorter: true, key: 'id' },
    { title: 'Period', dataIndex: ['billing_period'], sorter: true, render: (d: any) => moment(d).format('MMM YYYY'), width: 110 },
    { title: 'Currency', dataIndex: ['currency', 'iso'], width: 85 },
    { title: 'Total amount', render: (i: any) => `${i.currency.symbol}${(i.total/100).toLocaleString()}`, width: 125 },
    // { title: 'Paid', dataIndex: 'is_paid', render: (paid: boolean) => paid ? <CheckCircleTwoTone twoToneColor="limegreen" /> : <CloseCircleTwoTone twoToneColor="red" />, sorter: true, key: 'paid', width: 70 },
    { title: 'Due date', dataIndex: ['due_date'], render: (d: any) => moment(d).fromNow(), width: 120 },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (s: string, o: any) => (
        <Badge
          status={invoiceBadgeStatuses[s]}
          text={s}
        />
      ),
      width: 120
    },
    { title: 'Actions', render: (invoice: any) => <div className="row-actions">
        <Popconfirm title={`Confirm ${statusAdvanceAction[invoice.status]}`} onConfirm={() => doRecordDetailAction(invoice.id, 'advance')}>
          <a>{statusAdvanceAction[invoice.status]}</a>
        </Popconfirm>
        <a onClick={() => expandRow(invoice.id)}>Add files</a>
        <Popconfirm title="Confirm delete" onConfirm={() => deleteRecord(invoice.id)}>
          <a>Delete</a>
        </Popconfirm>
      </div>, width: 250 },
  ].map((e: any) => ({ ...e, ...allColsSettings }));
}

const statusMapper = (r: any) => ({ ...r, status: (moment(r.due_date).diff(moment(), 'days') < 0 && r.status === 'open') ? 'overdue': r.status})

  function Invoices() {
  const { query, handleFilter, handleSearch } = useQuery();
  const {
    pagination,
    handleChange,
    isLoading,
    createRecord,
    deleteRecord,
    doRecordDetailAction,
    recordList,
    firstFetch
  } = usePaginatedRecordList<any>({
    query,
    recordPath: 'journal-invoices',
    extraQueryAppend: '&expand=journal,currency',
    apiService: 'admin',
    pageSize: '15',
    initialAutoFetch: true
  });
  const elementRef = useRef<any>()
  const { tableExpandableConfig, onExpand, rowKeysMapper } = useRecordFiles('journal-invoices');

  return (
    <Container ref={elementRef}>
      <PageHeader
        title="Invoices"
        subTitle={`${pagination.total?.toLocaleString('en-US')} records`}
        extra={[
          <TabularFileImportWizard onSuccess={firstFetch} />,
          <FormDrawer
            btnIcon={<PlusOutlined />}
            recordName="Invoice"
            handleRecordAction={(data: any) => {
              createRecord({
                ...data,
                'billing_period': data['billing_period'].format('YYYY-MM-DD'),
                'due_date': data['due_date'].format('YYYY-MM-DD')
              })
            }}
          >
            <RecordSelect<OwnerType> recordModel={OwnerModel} formName="journal" valueKey="main_accounting_journal" />
            <RecordSelect<CurrencyType> recordModel={CurrencyModel} defaultValue="f36b4827-23a1-4a38-83c0-6640dd0c9f53" />
            <Form.Item
              name="billing_period"
              label="Billing period"
              initialValue={moment().startOf('month')}
              rules={[{ required: true }]}
            >
              <DatePicker.MonthPicker />
            </Form.Item>
            <Form.Item
              name="due_date"
              label="Due date"
              initialValue={moment().endOf('month')}
              rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </FormDrawer>
        ]}
        className="mb-md"
      >
        <RecordSelect
          recordModel={UnitModel}
          extraFilterQuery="&has_no_owner=false"
          filter
        />
        <DatePicker.MonthPicker
          defaultValue={!!query.get('billing_period') ? moment(query.get('billing_period')) : undefined}
          onChange={v => handleFilter('billing_period', v ? v.startOf('month').format('YYYY-MM-DD') : '')}
        />
        <RecordSelect
          recordModel={OwnerModel}
          filter
        />
      </PageHeader>

      <Table
        columns={getColumns(deleteRecord, doRecordDetailAction, onExpand)}
        dataSource={recordList.map(statusMapper).map(rowKeysMapper)}
        pagination={pagination}
        loading={isLoading}
        onChange={handleChange}
        scroll={{ y: elementRef.current?.clientHeight - 190 }}
        size="middle"
        expandable={tableExpandableConfig}
      />
    </Container>
  )
}

export default Invoices;
