import axios from "axios";


export let ancanaApitoken = null;

try {
  ancanaApitoken = JSON.parse(localStorage?.getItem('user') || '').token;
} catch (e) {

}

export const ancanaApi = axios.create({
  // baseURL: `https://api.ancana.co/v1/`,
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  timeout: 900000,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': ancanaApitoken ? `Token ${ancanaApitoken}` : ''
  }
});
