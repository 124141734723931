import { useEffect, useState } from "react";
import { Table, Badge, Popconfirm, message } from 'antd';
import dayjs from "dayjs";

import { dataApi } from "../api/dataApi";

const calendar = require('dayjs/plugin/calendar');
const day: any = dayjs.extend(calendar);

const handleSourceSync = (sourceCode: string, action = 'pull') => {
  dataApi.get(`sources/${sourceCode}/${action}/`).then((res: any) => {
    if (res.status === 200 && res.data.sync_started) {
      message.success(res.data.message);
      setTimeout(() => window.location.reload(), 1500);
    } else {
      message.error(res.data.message);
    }
  });
};

const columns = [
  { title: 'Name', dataIndex: 'name', key: 'name', render: (txt: any, record: any) => <a href={`/sources/${record.id}/syncs`}>{txt}</a>},
  {
    title: 'Status',
    render: (txt: any, obj: any) => {
      if (!obj.last_sync) return <>No sync yet</>;

      const sync_type = obj.last_sync.is_pull ? 'Pull' : 'Push';
      if (obj.last_sync.in_progress) {
        return <Badge status="processing" text={`${sync_type} in progress`} />
      } else {
        if (obj.last_sync.is_successful) {
          return <Badge status="success" text={`${sync_type} ${day(obj.last_sync.timestamp).calendar().toLowerCase()}`} />
        } else {
          return <Badge status="error" text={`${sync_type} failed ${day(obj.last_sync.timestamp).calendar().toLowerCase()}`} />
        }
      }
    }
  },
  {
    title: 'Actions',
    render: (txt: any, obj: any) => {
      return  (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {obj.allow_pull && (
            <Popconfirm
              title={`Do ${obj.name} pull`}
              onConfirm={() => handleSourceSync(obj.code, 'pull')}
              // onCancel={() => {}}
              okText="Yes"
              //cancelText="No"
            >
              <a href="#">Pull</a>
            </Popconfirm>
          )}
          {obj.allow_push && (
            <Popconfirm
              title={`Do ${obj.name} push`}
              onConfirm={() => handleSourceSync(obj.code, 'push')}
              // onCancel={() => {}}
              okText="Yes"
              //cancelText="No"
            >
              <a href="#">Push</a>
            </Popconfirm>
          )}
        </div>
      )
    }
  }
]

function Sources() {
  const [sources, setSources] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    dataApi.get('sources/').then((res: any) => {
      if (res.status === 200) {
        console.log('res', res.data.results[0]);
        setSources(res.data.results);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={sources}
        pagination={false}
        loading={loading}
      />
    </div>
  )
}

export default Sources;
